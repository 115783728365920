import React, { useState } from 'react';
import { TouchableOpacity, Text, View, StyleSheet } from 'react-native';
import Button from './Button';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from './PlatformModal';

// Utility function to get month name from month number
const getMonthName = (monthNumber) => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return monthNames[monthNumber];
};

const formatTime = (date) => {
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

const DateTimePickerWrapper = ({ value, onChange, ...props }) => {
  const [showPicker, setShowPicker] = useState(false);

  const handleDateChange = (selectedDate) => {
    setShowPicker(false);
    onChange(selectedDate);
  };

  // Date and time display in "Month Day, Year hh:mm AM/PM"
  const displayDate = `${getMonthName(value.getMonth())} ${value.getDate()}, ${value.getFullYear()} ${formatTime(value)}`;

  return (
    <View>
      <TouchableOpacity
        onPress={() => setShowPicker(true)}
        style={styles.dateDisplay}  // Apply styles to make it look more clickable
      >
        <Text style={styles.dateText}>{displayDate}</Text>
      </TouchableOpacity>

      <Modal
        animationType="slide"
        transparent={false}
        visible={showPicker}
        onRequestClose={() => {
          setShowPicker(false);
        }}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Button title="Close" onPress={() => setShowPicker(false)} />
            <ReactDatePicker
              selected={value}
              showTimeSelect
              timeIntervals={1}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy h:mm aa"
              {...props}
              customInput={<CustomInput />}
              popperPlacement="bottom-start"
            />
          </View>
        </View>
      </Modal>
    </View>
  );
};

// Custom Input Component to Hide Native Input
const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <button className="custom-date-picker-input" onClick={onClick} ref={ref}>
    {value}
  </button>
));

const styles = StyleSheet.create({
  dateDisplay: {
    padding: 10,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: '#f9f9f9',
    width: '100%',
    maxWidth: 250, // Limit the width
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  dateText: {
    color: '#333',
    fontSize: 16,
    textAlign: 'center',
  },
  dateDisplayHover: {
    backgroundColor: '#f0f0f0', // Slight hover effect
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 22,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    maxHeight: '80%', // Limit the height of the modal
    width: '90%', // Ensure the modal width is manageable
  },
  customDatePickerInput: {
    display: 'none', // Hide native input
  },
  // Additional styles for button inside the picker
  'react-datepicker-wrapper': {
    width: '100%',
  },
});

export default DateTimePickerWrapper;
