import React from "react";
import { View, Text, Switch } from "react-native";
import colors from "../../config/colors";

function AppSwitch({ labelText, switchState, setSwitchState, isDisabled }) {
  return (
  <View style={{alignItems:"flex-start", paddingTop: 20, paddingBottom: 20}}>
    <Text>{labelText}</Text>
    <Switch 
    trackColor={{ false: "#767577", true: colors.primary }}
    thumbColor={switchState ? "#fafafa" : colors.primary}
    onValueChange={()=>setSwitchState(!switchState)}
    value={switchState}
    disabled={isDisabled}
  />
  </View>
  );
}

export default AppSwitch;
