import React, {useState} from 'react';
import { View, Text, TextInput, Switch, StyleSheet, TouchableOpacity } from 'react-native';
import { AntDesign } from '../PlatformSpecificIcons';
import { Buffer } from 'buffer';
import colors from "../../config/colors";
import { FrameType } from './frameType';
//import styles from './styles'; // Note: You'll need to ensure that styles are imported or defined in this file

const PingFrameComponent = ({ frameCore, index, updateFrameCore }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
  
    const toggleSkipToAction = (newValue) => {
      updateFrameCore({
        ...frameCore,
        skipToActionOnFailure: newValue,
      });
    };

    const toggleEnableJitter = (newValue) => {
      updateFrameCore({
        ...frameCore,
        enableJitter: newValue,
        jitter: newValue ? 50 : 0,
      });
    };
  
    return (
      <View>
        <TouchableOpacity onPress={() => setIsCollapsed(!isCollapsed)}>
        <View
          style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: colors.primary,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              padding: 10,
            }}>
          <Text style={{fontWeight: "bold", color: "white"}}>Frame {index + 1}: Ping Check Frame</Text>
          {!isCollapsed && <AntDesign name="upcircle" size={28} color="white" />}
          {isCollapsed && <AntDesign name="setting" size={32} color="white" />}
        </View>
        </TouchableOpacity>

        {!isCollapsed && <><View style={{padding: 10}}>
        <Text>Domain To Check:</Text>
        <TextInput
          style={styles.inputWide}
          placeholder="Domain To Check"
          value={frameCore.targetURL}
          onChangeText={(text) => updateFrameCore({ ...frameCore, targetURL: text })}
        />
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Loop Repeat:</Text>
          <TextInput
            style={styles.input}
            placeholder="Loop Repeat"
            value={isNaN(frameCore.loopRepeat) ? '' : frameCore.loopRepeat.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, loopRepeat: parseInt(text) })
            }
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Loop to Starts:</Text>
          <TextInput
            style={styles.input}
            placeholder="Loop to Starts"
            value={isNaN(frameCore.loopToStarts) ? '' : frameCore.loopToStarts.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, loopToStarts: parseInt(text) })
            }
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Skip to Action On Failure:</Text>
          <Switch
            value={frameCore.skipToActionOnFailure}
            onValueChange={toggleSkipToAction}
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Number of Pings{'\n'}to Send:</Text>
          <TextInput
            style={styles.input}
            placeholder="Number of Pings to Send"
            value={isNaN(frameCore.numberOfPings) ? '' : frameCore.numberOfPings.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, numberOfPings: parseInt(text) })
            }
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Ping Interval{'\n'}(Milliseconds):</Text>
          <TextInput
            style={styles.input}
            placeholder="Ping Interval"
            value={isNaN(frameCore.pingInterval) ? '' : frameCore.pingInterval.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, pingInterval: parseInt(text) })
            }
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Replies Required{'\n'}for Success:</Text>
          <TextInput
            style={styles.input}
            placeholder="Replies Required for Success"
            value={isNaN(frameCore.repliesForSuccess) ? '' : frameCore.repliesForSuccess.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, repliesForSuccess: parseInt(text) })
            }
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Ping Size{'\n'}(bytes):</Text>
          <TextInput
            style={styles.input}
            placeholder="Ping Size"
            value={isNaN(frameCore.pingSize) ? '' : frameCore.pingSize.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, pingSize: parseInt(text) })
            }
          />
        </View>

  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Ping Timeout:{'\n'}(Milliseconds)</Text>
          <TextInput
            style={styles.input}
            placeholder="Timeout"
            value={isNaN(frameCore.timeout) ? '' : frameCore.timeout.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, timeout: parseInt(text) })
            }
          />
        </View>
          
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Trigger On Jitter:</Text>
          <Switch
            value={frameCore.enableJitter}
            onValueChange={toggleEnableJitter}
          />
        </View>
  
        {frameCore.enableJitter && <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Jitter Trigger:{'\n'}(Milliseconds)</Text>
          <TextInput
            style={styles.input}
            placeholder="Jitter"
            value={isNaN(frameCore.jitter) ? '' : frameCore.jitter.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, jitter: parseInt(text) })
            }
          />
        </View>}
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Delay Before{'\n'}Proceed: (Seconds)</Text>
          <TextInput
            style={styles.input}
            placeholder="Delay Before Proceed"
            value={isNaN(frameCore.delayBeforeProceed) ? '' : frameCore.delayBeforeProceed.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, delayBeforeProceed: parseInt(text) })
            }
          />
        </View></View></>}
      </View>
    );
  };
  
  PingFrameComponent.newFrameCore = () => ({
    frameType: FrameType.PING_FRAME,
    version: 1,
    targetURL: 'www.google.com',
    loopRepeat: 0,
    loopToStarts: 0,
    skipToActionOnFailure: false,
    numberOfPings: 5,
    pingInterval: 1000,
    repliesForSuccess: 1,
    pingSize: 32,
    timeout: 1000,
    enableJitter: false,
    jitter: 0,
    delayBeforeProceed: 0,
  });
  
  PingFrameComponent.toBytes = (frameCore) => {
    const bytes = [];
  
    const intToBytes = (num) => [
        num & 0xff,
        (num >> 8) & 0xff,
        (num >> 16) & 0xff,
        (num >> 24) & 0xff,
      ];
      
    const uint16ToBytes = (num) => [
        num & 0xff,
        (num >> 8) & 0xff,
      ];
  
    // Convert smaller integers to 1-byte or 2-byte arrays
    const uint8ToBytes = (num) => [num & 0xff];
  
    // Convert strings to byte arrays
    const stringToBytes = (str) => Buffer.from(str, 'utf8');
  
    // Add fields to bytes
    bytes.push(FrameType.PING_FRAME); 
    bytes.push(frameCore.version); // Assuming version is uint8_t
    bytes.push(...uint8ToBytes(frameCore.loopRepeat));
    bytes.push(...uint8ToBytes(frameCore.loopToStarts));
    bytes.push(frameCore.skipToActionOnFailure ? 1 : 0);
    const targetURLBytes = stringToBytes(frameCore.targetURL);
    bytes.push(...uint8ToBytes(targetURLBytes.length), ...targetURLBytes);
    bytes.push(...intToBytes(frameCore.timeout));
    bytes.push(...uint16ToBytes(frameCore.numberOfPings));
    bytes.push(...intToBytes(frameCore.pingInterval));
    bytes.push(...uint16ToBytes(frameCore.repliesForSuccess));
    bytes.push(...uint16ToBytes(frameCore.pingSize));
    bytes.push(...uint16ToBytes(frameCore.jitter));
    bytes.push(...intToBytes(frameCore.delayBeforeProceed * 1000));
    bytes.push(0, 0, 0, 0, 0); // Spare bytes

    // Now that the byte array is complete, calculate its size
    const size = bytes.length;

    // Convert the size to a uint16 byte array
    const sizeBytes = uint16ToBytes(size);

    // Insert the size at the second position in the bytes array
    bytes.splice(1, 0, ...sizeBytes);
  
    return bytes;
  };
  
  PingFrameComponent.fromBytes = (bytes, offset) => {
    // Convert byte arrays to integers

    const bytesToInt = (arr) =>
      arr[3] << 24 | arr[2] << 16 | arr[1] << 8 | arr[0];
    
    const bytesToUint16 = (arr) =>
      arr[1] << 8 | arr[0];
  
    // Convert smaller byte arrays to integers
    const bytesToUint8 = (arr) => arr[0];
  
    // Convert byte arrays to strings
    const bytesToString = (arr) => Buffer.from(arr).toString('utf8');
  
    let index = offset;
  
    // Read fields from bytes
    const frameSize = bytesToUint16(bytes.slice(index, index + 2));
    index += 2;
    const version = bytes[index++]; // Assuming version is uint8_t
    const loopRepeat = bytesToUint8(bytes.slice(index, index + 1));
    index++;
    const loopToStarts = bytesToUint8(bytes.slice(index, index + 1));
    index++;
    const skipToActionOnFailure = bytesToUint8(bytes.slice(index, index + 1)) == 1;
    index++;
    const targetURLLen = bytesToUint8(bytes.slice(index, index + 1));
    index++;
    const targetURL = bytesToString(bytes.slice(index, index + targetURLLen));
    index += targetURLLen;
    const timeout = bytesToInt(bytes.slice(index, index + 4));
    index += 4;
    const numberOfPings = bytesToUint16(bytes.slice(index, index + 2));
    index += 2;
    const pingInterval = bytesToInt(bytes.slice(index, index + 2));
    index += 4;
    const repliesForSuccess = bytesToUint16(bytes.slice(index, index + 2));
    index += 2;
    const pingSize = bytesToUint16(bytes.slice(index, index + 2));
    index += 2;
    const jitter = bytesToUint16(bytes.slice(index, index + 2));
    index += 2;
    const delayBeforeProceed = bytesToInt(bytes.slice(index, index + 4))/1000;
    index += 4;
    index += 5; // Spare bytes
  
    const frameCore = {
      frameType: FrameType.PING_FRAME,
      version,
      targetURL,
      loopRepeat,
      loopToStarts,
      skipToActionOnFailure,
      timeout,
      numberOfPings,
      pingInterval,
      repliesForSuccess,
      pingSize,
      enableJitter: jitter > 0 ? true:false,
      jitter,
      delayBeforeProceed,
    };
  
    const bytesRead = index - offset;
  
    return { frameCore, bytesRead };
  };

  const styles = StyleSheet.create({
    TextStyle: {
            color: colors.primary,
            alignSelf: "center",
        },
    picker: {
            height: 50,
            width: '75%',
            alignSelf: 'center',  // Center the picker
            },
            frameCore: {
            borderWidth: 1,
            borderRadius: 10,
            borderColor: colors.primary,
            margin: 10,
            padding: 10,
            },
            input: {
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 4,
            padding: 5,
            marginBottom: 10,
            width: '50%', // use the full width
            },
            inputWide: {
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 4,
            padding: 5,
            marginBottom: 10,
            width: '100%', // use the full width
            },
    });

  export default PingFrameComponent;