import AsyncStorage from '@react-native-async-storage/async-storage';

const store = async (key, data) => {
        try {
          // console.log(data);
          // console.log(key);
          await AsyncStorage.setItem(key, JSON.stringify(data));
        } catch (error) {
          console.log("Error storing", error);
        }
};

const get = async (key) => {
          try {
            return await AsyncStorage.getItem(key);
          } catch (error) {
            console.log("Error getting", error);
          }
};

export default { store, get };
