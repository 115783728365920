import React, {useState} from 'react';
import { View, Text, TextInput, Switch, StyleSheet, TouchableOpacity } from 'react-native';
import { AntDesign } from '../PlatformSpecificIcons';
import { Buffer } from 'buffer';
import colors from "../../config/colors";
import { FrameType } from './frameType';
//import styles from './styles'; // Note: You'll need to ensure that styles are imported or defined in this file

const URLCheckFrameComponent = ({ frameCore, index, updateFrameCore }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleResponseCheck = (newValue) => {
      updateFrameCore({
        ...frameCore,
        checkResponse: newValue,
        responseType: newValue ? frameCore.responseType : 0,
      });
    };
  
    const toggleContentCheck = (newValue) => {
      updateFrameCore({
        ...frameCore,
        checkContent: newValue,
        content: newValue ? frameCore.content : '',
      });
    };
  
    const toggleSkipToAction = (newValue) => {
      updateFrameCore({
        ...frameCore,
        skipToActionOnFailure: newValue,
      });
    };
  
    return (
      <View>
        <TouchableOpacity onPress={() => setIsCollapsed(!isCollapsed)}>
        <View
          style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: colors.primary,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              padding: 10,
            }}>
          <Text style={{fontWeight: "bold", color: "white"}}>Frame {index + 1}: URL Check Frame</Text>
          {!isCollapsed && <AntDesign name="upcircle" size={28} color="white" />}
          {isCollapsed && <AntDesign name="setting" size={32} color="white" />}
        </View>
        </TouchableOpacity>
  
        {!isCollapsed && <><View style={{padding: 10}}><Text>URL To Check:</Text>
        <TextInput
          style={styles.inputWide}
          placeholder="URL To Check"
          value={frameCore.targetURL}
          onChangeText={(text) => updateFrameCore({ ...frameCore, targetURL: text })}
        />
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Check for specific response:</Text>
          <Switch
            value={frameCore.checkResponse}
            onValueChange={toggleResponseCheck}
          />
        </View>
        {frameCore.checkResponse && (
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text>Response Type:</Text>
            <TextInput
              style={styles.input}
              placeholder="Response Type"
              value={isNaN(frameCore.responseType) ? '' : frameCore.responseType.toString()}
              onChangeText={(text) =>
                updateFrameCore({ ...frameCore, responseType: parseInt(text) })
              }
            />
          </View>
        )}
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Check for response content:</Text>
          <Switch value={frameCore.checkContent} onValueChange={toggleContentCheck} />
        </View>
        {frameCore.checkContent && (
          <>
            <Text>Content:</Text>
            <TextInput
              style={styles.inputWide}
              placeholder="Content"
              value={frameCore.content}
              onChangeText={(text) => updateFrameCore({ ...frameCore, content: text })}
            />
          </>
        )}
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Loop Repeat:</Text>
          <TextInput
            style={styles.input}
            placeholder="Loop Repeat"
            value={isNaN(frameCore.loopRepeat) ? '' : frameCore.loopRepeat.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, loopRepeat: parseInt(text) })
            }
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Loop to Starts:</Text>
          <TextInput
            style={styles.input}
            placeholder="Loop to Starts"
            value={isNaN(frameCore.loopToStarts) ? '' : frameCore.loopToStarts.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, loopToStarts: parseInt(text) })
            }
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Skip to Action On Failure:</Text>
          <Switch
            value={frameCore.skipToActionOnFailure}
            onValueChange={toggleSkipToAction}
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Timeout:</Text>
          <TextInput
            style={styles.input}
            placeholder="Timeout"
            value={isNaN(frameCore.timeout) ? '' : frameCore.timeout.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, timeout: parseInt(text) })
            }
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Delay Before{'\n'}Proceed: (Seconds)</Text>
          <TextInput
            style={styles.input}
            placeholder="Delay Before Proceed"
            value={isNaN(frameCore.delayBeforeProceed) ? '' : frameCore.delayBeforeProceed.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, delayBeforeProceed: parseInt(text) })
            }
          />
        </View></View></>}
      </View>
    );
  };
  
  URLCheckFrameComponent.newFrameCore = () => ({
    frameType: FrameType.URL_CHECK_FRAME,
    version: 1,
    targetURL: 'www.google.com',
    checkResponse: false,
    responseType: 0,
    checkContent: false,
    content: '',
    loopRepeat: 0,
    loopToStarts: 0,
    skipToActionOnFailure: false,
    timeout: 20,
    delayBeforeProceed: 0,
  });
  
  URLCheckFrameComponent.toBytes = (frameCore) => {
    const bytes = [];
  
    // Convert integers to 4-byte arrays

    const intToBytes = (num) => [
        num & 0xff,
        (num >> 8) & 0xff,
        (num >> 16) & 0xff,
        (num >> 24) & 0xff,
      ];
      
    const uint16ToBytes = (num) => [
        num & 0xff,
        (num >> 8) & 0xff,
      ];
  
    // Convert smaller integers to 1-byte or 2-byte arrays
    const uint8ToBytes = (num) => [num & 0xff];
  
    // Convert strings to byte arrays
    const stringToBytes = (str) => Buffer.from(str, 'utf8');
  
    // Add fields to bytes
    bytes.push(FrameType.URL_CHECK_FRAME); 
    bytes.push(frameCore.version); // Assuming version is uint8_t
    bytes.push(...uint8ToBytes(frameCore.loopRepeat));
    bytes.push(...uint8ToBytes(frameCore.loopToStarts));
    bytes.push(frameCore.skipToActionOnFailure ? 1 : 0);
    const targetURLBytes = stringToBytes(frameCore.targetURL);
    bytes.push(...uint8ToBytes(targetURLBytes.length), ...targetURLBytes);
    bytes.push(...uint16ToBytes(frameCore.responseType));
    const contentBytes = stringToBytes(frameCore.content);
    bytes.push(...uint8ToBytes(contentBytes.length), ...contentBytes);
    bytes.push(...intToBytes(frameCore.timeout * 1000));
    bytes.push(...intToBytes(frameCore.delayBeforeProceed * 1000));
    bytes.push(0, 0, 0, 0, 0); // Spare bytes

    // Now that the byte array is complete, calculate its size
    const size = bytes.length;

    // Convert the size to a uint16 byte array
    const sizeBytes = uint16ToBytes(size);

    // Insert the size at the second position in the bytes array
    bytes.splice(1, 0, ...sizeBytes);
  
    return bytes;
  };
  
  URLCheckFrameComponent.fromBytes = (bytes, offset) => {
    // Convert byte arrays to integers

    const bytesToInt = (arr) =>
      arr[3] << 24 | arr[2] << 16 | arr[1] << 8 | arr[0];
    
    const bytesToUint16 = (arr) =>
      arr[1] << 8 | arr[0];
  
    // Convert smaller byte arrays to integers
    const bytesToUint8 = (arr) => arr[0];
  
    // Convert byte arrays to strings
    const bytesToString = (arr) => Buffer.from(arr).toString('utf8');
  
    let index = offset;
  
    // Read fields from bytes
    const frameSize = bytesToUint16(bytes.slice(index, index + 2));
    index += 2;
    const version = bytes[index++]; // Assuming version is uint8_t
    const loopRepeat = bytesToUint8(bytes.slice(index, index + 1));
    index++;
    const loopToStarts = bytesToUint8(bytes.slice(index, index + 1));
    index++;
    const skipToActionOnFailure = bytesToUint8(bytes.slice(index, index + 1)) == 1;
    index++;
    const targetURLLen = bytesToUint8(bytes.slice(index, index + 1));
    index++;
    const targetURL = bytesToString(bytes.slice(index, index + targetURLLen));
    index += targetURLLen;
    const responseType = bytesToUint16(bytes.slice(index, index + 2));
    index += 2;
    const contentLen = bytesToUint8(bytes.slice(index, index + 1));
    index++;
    const content = bytesToString(bytes.slice(index, index + contentLen));
    index += contentLen;
    const timeout = bytesToInt(bytes.slice(index, index + 4))/1000;
    index += 4;
    const delayBeforeProceed = bytesToInt(bytes.slice(index, index + 4))/1000;
    index += 4;
    index += 5; // Spare bytes
  
    const frameCore = {
      frameType: FrameType.URL_CHECK_FRAME,
      version,
      targetURL,
      checkResponse: responseType !== 0,
      responseType,
      checkContent: content !== '',
      content,
      loopRepeat,
      loopToStarts,
      skipToActionOnFailure,
      timeout,
      delayBeforeProceed,
    };
  
    const bytesRead = index - offset;
  
    return { frameCore, bytesRead };
  };

  const styles = StyleSheet.create({
    TextStyle: {
            color: colors.primary,
            alignSelf: "center",
        },
    picker: {
            height: 50,
            width: '75%',
            alignSelf: 'center',  // Center the picker
            },
            frameCore: {
            borderWidth: 1,
            borderRadius: 10,
            borderColor: colors.primary,
            margin: 10,
            padding: 10,
            },
            input: {
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 4,
            padding: 5,
            marginBottom: 10,
            width: '50%', // use the full width
            },
            inputWide: {
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 4,
            padding: 5,
            marginBottom: 10,
            width: '100%', // use the full width
            },
    });

  export default URLCheckFrameComponent;