import React, { useState, useEffect } from "react";
import { View, TextInput, StyleSheet } from "react-native";
import { MaterialCommunityIcons } from "./PlatformSpecificIcons";
import defaultStyles from "../config/styles";

function AppTextInput({ icon, width = "100%", inputRef, value, onChangeText, ...otherProps }) {
  const [internalValue, setInternalValue] = useState(value || '');

  // If a ref is passed, update the ref with the current input value
  useEffect(() => {
    if (inputRef) {
      inputRef.current = internalValue; // Sync ref with current value
    }
  }, [internalValue, inputRef]);

  const handleChangeText = (text) => {
    setInternalValue(text); // Update local state
    if (onChangeText) {
      onChangeText(text); // Trigger external onChangeText callback
    }
  };

  return (
    <View style={[styles.container, { width }]}>
      {icon && (
        <MaterialCommunityIcons
          name={icon}
          size={20}
          color={defaultStyles.colors.medium}
          style={styles.icon}
        />
      )}
      <TextInput
        placeholderTextColor={defaultStyles.colors.medium}
        style={[defaultStyles.text, { flex: 1 }]}
        value={internalValue} // Controlled input via internal state
        onChangeText={handleChangeText} // Handle text changes
        {...otherProps}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: defaultStyles.colors.light,
    borderRadius: 25,
    flexDirection: "row",
    padding: 15,
    marginVertical: 10,
  },
  icon: {
    marginRight: 10,
  },
});

export default AppTextInput;
