import React, { useEffect, useState, useRef } from 'react';
import { Text, View, StyleSheet, Linking, ActivityIndicator, RefreshControl, Platform, Image, ScrollView} from 'react-native';
import moment from 'moment-timezone';
import { Foundation, AntDesign, MaterialCommunityIcons, MaterialIcons } from '../components/PlatformSpecificIcons';
import { Popable } from 'react-native-popable';
import Modal from '../components/PlatformModal';
import TextInput from '../components/TextInputRef';
//import { Dropdown } from 'react-native-material-dropdown';
//import RNPickerSelect from "@react-native-community/picker";

import Screen from '../components/ScreenWithoutScrollView';
import Button from '../components/Button';
import routes from "../navigation/routes";
import useAuth from "../auth/useAuth";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import { ErrorMessage, ErrorMessage2 } from "../components/forms";
import colors from '../config/colors';
import { TouchableOpacity } from 'react-native-gesture-handler';
import ModifyFolderModal from '../components/ModifyFolderModal';

// import DraggableFlatList, {
//     ScaleDecorator,
//     RenderItemParams,
//     NestableScrollContainer,
//     NestableDraggableFlatList
//   } from "@bwjohns4/react-native-draggable-flatlist";
//import { createNativeWrapper } from 'react-native-gesture-handler';

const { DraggableFlatList, ScaleDecorator, RenderItemParams, NestableScrollContainer, NestableDraggableFlatList } = 
  Platform.OS === 'web'
    ? require('react-native-draggable-flatlist')  // Official version for web
    : require('@bwjohns4/react-native-draggable-flatlist');  // Forked version for native


function ColumnView({ 
    data, 
    column, 
    updateColumnFolder, 
    renderItemOrFolder, 
    loadKeepConnects, 
    refreshing, 
    handleCreateNewFolder,
    navigation,
    setUpdateNeeded,
    removeColumn,
    isMainColumn,
    setNewServerOrder
}){

    useEffect(() => {
        console.log('ColumnView rendered 1st time');
    }, []);

    console.log('ColumnView rendered');
    const navigateUp = () => {
        const newStack = [...column.folderStack];
        const previousFolder = newStack.pop();
        updateColumnFolder(column.id, previousFolder ? previousFolder.id : 'root', newStack);
    };

    const navigateToFolder = (folder) => {
        const newStack = [...column.folderStack, { id: column.currentFolder, name: folder.Name }];
        updateColumnFolder(column.id, folder.id, newStack);
    };

    return (
        <>
            <View style={styles.columnHeader}>
                <TouchableOpacity onPress={() => updateColumnFolder(column.id, 'root', [])} style={styles.headerIcon}>
                    <AntDesign name="home" size={24} color={colors.primary} />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleCreateNewFolder(column.id, column.currentFolder)} style={styles.headerIcon}>
                    <Foundation name="folder-add" size={24} color={colors.primary} />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => navigation.navigate(routes.ADDDEVICE, {
                    folderId: column.currentFolder,
                    onDeviceAdded: () => setUpdateNeeded(true)
                })} style={styles.headerIcon}>
                    <MaterialIcons name="add-circle-outline" size={24} color={colors.primary} />
                </TouchableOpacity>
                {!isMainColumn && (
                    <TouchableOpacity onPress={() => removeColumn(column.id)} style={styles.headerIcon}>
                        <MaterialCommunityIcons name="table-column-remove" size={24} color="red" />
                    </TouchableOpacity>
                )}
            </View>
            
            {column.currentFolder !== 'root' && (
                <View style={styles.breadcrumbContainer}>
                        <View style={styles.breadcrumbContainer}>
                            <Text style={styles.currentFolderText}>
                                {column.currentFolder === 'root' ? 'Root' : column.folderStack[column.folderStack.length - 1]?.name}
                            </Text>
                        </View>
                    {column.folderStack.length > 0 && (
                        <TouchableOpacity onPress={navigateUp} style={styles.backButtonContainer}>
                        <Text style={styles.backButton}>
                            ← {column.folderStack.length > 1 
                                ? column.folderStack[column.folderStack.length - 2].name 
                                : 'Back to Root'}
                        </Text>
                        </TouchableOpacity>
                    )}
                </View>
            )}

            <NestableDraggableFlatList
                data={data}
                onDragEnd={({ data }) => {
                    setNewServerOrder(data, column.currentFolder);
                }}
                keyExtractor={(item) => item.id}
                renderItem={(params) => renderItemOrFolder({ ...params, navigateToFolder, columnId: column.id })}
                initialNumToRender={10}
                windowSize={10}
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={loadKeepConnects}
                    />
                }
            />
        </>
    );
};


function OverviewScreen({ navigation }) {
    const auth = useAuth();
    const [keepConnectsArray, setKeepConnectsArray] = useState([{ label: "No Keep Connects Registered", value: "null" }]);
    const [isSubscribed, setSubscription] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const getKeepConnectsFullData = useApi(keepConnectApi.getKeepConnectsFullData);
    const updateItemOrder = useApi(keepConnectApi.updateItemOrder);
    const updateServerItems = useApi(keepConnectApi.updateServerItemsFull);
    const [dirtyData, setDirtyData] = useState(false);
    const moveDeviceToFolderApi = useApi(keepConnectApi.moveDeviceToFolder);
    const setAppSettings = useApi(keepConnectApi.setAppSettings);
    const addFolder = useApi(keepConnectApi.addFolder);
    const removeFolder = useApi(keepConnectApi.removeFolder);
    const [requestFailed, setRequestFailed] = useState(false);
    const [requestMessage, setRequestMessage] = useState("Error Communicating with Server");
    const [errorFromServer, setErrorFromServer] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [emptyKeepConnects, setEmptyKeepConnects] = useState(true);
    const [updateNeeded, setUpdateNeeded] = useState(false);
    const [keepConnectData, setKeepConnectData] = useState([]);
    const [momentNow, setMomentNow] = useState();
    const newFolderNameRef = useRef('');
    const modalRef = useRef();
    const newFolderModalRef = useRef();
    const selectedFolderIdRef = useRef(null);
    //const [selectedFolderId, setSelectedFolderId] = useState(null);
    const moveToFolderModalRef = useRef();
    const selectedDeviceIdRef = useRef(null);
    const modifyFolderModalRef = useRef(null);
    const [appSettings, setAppSettingsState] = useState(null);
    const isDesktop = Platform.OS === 'windows' || Platform.OS === 'macos';
    const [columns, setColumns] = useState([
        { id: 'main', currentFolder: 'root', folderStack: [] }
    ]);
    //const [activeColumn, setActiveColumn] = useState(null);
    const activeColumnRef = useRef();


    useEffect(() => {
        loadKeepConnects(true);
        // Set an interval to run every 5 minutes (300000 milliseconds)
        const intervalId = setInterval(() => {
            loadKeepConnects();
        }, 300000);

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const loadKeepConnects = async (isInitialLoad) => {
        //setIsLoading(true);
        //console.log("async loading");
        setRefreshing(true);
        //const response = await getKeepConnectApi.request();
        const response = await getKeepConnectsFullData.request();
        console.log(response.data.data);
        //setKeepConnectData(response.data.data)

        if (!response.ok) {
            if (response.data && response.data.data && response.data.data.status && response.data.data.status == 403) return auth.logOut();
            setRequestFailed(true);
            setSubscription(true);
            setEmptyKeepConnects(true);
            setIsLoading(false);
            setRefreshing(false);
            return;
        } else setRequestFailed(false);
        if (response.data && response.data.hasOwnProperty('subscription')) { //subscription is only included in message if NOT subscribed
            setIsLoading(false);
            setRefreshing(false);
            setSubscription(false);
            return;
        }
        if (!response.data.data.items) {
            setSubscription(true);
            setEmptyKeepConnects(true);
            setIsLoading(false);
            setRefreshing(false);
            setKeepConnectData(response.data.data);
            setKeepConnectsArray([{ label: "No Keep Connects Registered", value: "null" }]);
            navigation.navigate(routes.ADDDEVICE, { changedDevices: setUpdateNeeded, initialSetup: true })
            return;
        }
        setSubscription(true);
        setEmptyKeepConnects(false);
        //console.log(response.data.settings);

        if (response.data.settings.license) {
            await keepConnectStore.store("license", response.data.settings.license.replace(/\"/g, ""));
            auth.setUserPermissions({license: response.data.settings.license?.replace(/\"/g, ""), role: response.data.settings.role?.replace(/\"/g, "")});
        } else {
            await keepConnectStore.store("license", "basic");
            auth.setUserPermissions({license: "basic", role: "admin"});
        }

        auth.setMqttParams(response.data.settings?.mqtt);

        if (response.data.settings.kc_appTimezone) {
            await keepConnectStore.store("kc_appTimezone", response.data.settings.kc_appTimezone);
        } else {
            await keepConnectStore.store("kc_appTimezone", "America/New_York");
        }

        if(Platform.OS === 'web' && isInitialLoad && response.data.settings){
            setAppSettingsState(response.data?.settings);
            loadSavedView(response.data.settings.savedView, response.data.data.items);
        }
        // console.log(response.data.data);
        //console.log(response.data.deviceOrder);
        moment.tz.setDefault("America/New_York");
        setMomentNow(moment().tz("America/New_York"));

        setKeepConnectData(response.data.data.items);

        // if (response.data.deviceOrder[0] != "") {
        //     updateServerDeviceOrder(response.data.data, response.data.deviceOrder);
        // } else {
        //     //console.log("No Device Order");
        //     setKeepConnectData(response.data.data);
        // }
        setIsLoading(false);
        setRefreshing(false);

    }

    useEffect(() => {
        if (updateNeeded) {
            if (Platform.OS === 'web' || isDesktop) {
                setIsLoading(true);
            }
            setRefreshing(true);
            setUpdateNeeded(false);
            loadKeepConnects();
        }
    }, [updateNeeded]); // This effect will run only when `updateNeeded` changes
    

    const setNewServerOrder = async (data, folderId) => {
        const updateFolderItems = (items, targetFolderId, newOrder) => {
            return items.map(item => {
                if (item.id === targetFolderId && item.type === 'folder') {
                    return { ...item, items: newOrder };
                } else if (item.type === 'folder' && item.items) {
                    return { ...item, items: updateFolderItems(item.items, targetFolderId, newOrder) };
                }
                return item;
            });
        };
        
        let kcData;
        setKeepConnectData(prevData => {
            if (folderId === 'root') {
                kcData = data;
                return data;
            } else {
                kcData = updateFolderItems(prevData, folderId, data);
                return kcData;
            }
        });
        
        updateServerItems.request(kcData);

    };

    const handleModifyFolder = (action, folderId, newName) => {
        switch(action) {
            case 'rename':
                if (newName) {
                    setKeepConnectData(prevData => {
                        const updateFolderName = (items) => {
                            return items.map(item => {
                                if (item.id === folderId && item.type === 'folder') {
                                    return { ...item, Name: newName };
                                } else if (item.type === 'folder' && item.items) {
                                    return { ...item, items: updateFolderName(item.items) };
                                }
                                return item;
                            });
                        };
                        const updatedData = updateFolderName(prevData);
                        updateServerItems.request(updatedData);
                        return updatedData;
                    });
                }
                break;
            case 'move':
                // Reuse the existing move folder modal
                selectedFolderIdRef.current = folderId;
                moveToFolderModalRef.current?.openModal();
                break;
            case 'delete':
                // Reuse the existing delete folder confirmation
                selectedFolderIdRef.current = folderId;
                modalRef.current?.openModal();
                break;
        }
    };

    // Update handleCreateNewFolder to work with specific columns
    const handleCreateNewFolder = (columnId, currentFolder) => {
        //setActiveColumn({ id: columnId, currentFolder: currentFolder });
        //setNewFolderModalVisible(true);
        activeColumnRef.current = { id: columnId, currentFolder: currentFolder };
        newFolderModalRef.current?.openModal();
    };

    const confirmCreateNewFolder = async () => {
        // Capture current values from refs before the state update closure
        const newFolderName = newFolderNameRef.current;
        const activeColumn = activeColumnRef.current;
    
        if (newFolderName && activeColumn) {
            // Close modal and start loading
            newFolderModalRef.current?.closeModal();
            setIsLoading(true);
    
            // Define the new folder object
            const newFolder = {
                type: 'folder',
                id: `folder_${Date.now()}`,
                Name: newFolderName,
                items: []
            };
    
            setKeepConnectData(prevData => {
                let updatedData = JSON.parse(JSON.stringify(prevData));
    
                const addFolderToItems = (items, targetFolderId) => {
                    for (let i = 0; i < items.length; i++) {
                        if (items[i].id === targetFolderId && items[i].type === 'folder') {
                            // Initialize the folder's items array if it doesn't exist
                            if (!Array.isArray(items[i].items)) {
                                items[i].items = [];
                            }
    
                            // Add the new folder and ensure no null entries
                            items[i].items.unshift(newFolder);
                            items[i].items = items[i].items.filter(item => item !== null); // Clean nulls
                            return true;
                        }
                        if (items[i].type === 'folder' && Array.isArray(items[i].items)) {
                            if (addFolderToItems(items[i].items, targetFolderId)) {
                                return true;
                            }
                        }
                    }
                    return false;
                };
    
                if (activeColumn.currentFolder === 'root') {
                    // Add the new folder to the beginning of the root items array
                    updatedData.unshift(newFolder);
                    updatedData = updatedData.filter(item => item !== null);  // Clean nulls in root
                } else {
                    addFolderToItems(updatedData, activeColumn.currentFolder);
                }
    
                // Clean up null items throughout the structure to prevent render issues
                const cleanData = (items) => {
                    return items
                        .filter(item => item !== null)  // Remove null items
                        .map(item => {
                            if (item.type === 'folder' && Array.isArray(item.items)) {
                                item.items = cleanData(item.items);  // Recursively clean folders
                            }
                            return item;
                        });
                };
    
                updatedData = cleanData(updatedData);
    
                console.log("Final updated data structure:", JSON.stringify(updatedData, null, 2));
                updateServerItems.request(updatedData);  // Sync with the server
                return updatedData;
            });
    
            // End loading and reset inputs
            setIsLoading(false);
            newFolderNameRef.current = '';  // Clear folder name
            activeColumnRef.current = null;  // Reset active column
        } else {
            console.error("New folder name or active column is missing");
        }
    };
    
        
        const confirmDeleteFolder = async () => {
            if (selectedFolderIdRef.current) {
                modalRef.current?.closeModal();
                setIsLoading(true);
                let selectedFolderId = selectedFolderIdRef.current;
        
                setKeepConnectData(prevData => {
                    const removeFolderFromItems = (items) => {
                        return items.filter(item => item.id !== selectedFolderId)
                            .map(item => {
                                if (item.type === 'folder' && item.items) {
                                    return { ...item, items: removeFolderFromItems(item.items) };
                                }
                                return item;
                            });
                    };
        
                    const updatedData = removeFolderFromItems(prevData);
                    updateServerItems.request(updatedData);
                    return updatedData;
                });
        
                setIsLoading(false);
                selectedFolderIdRef.current = null;
            }
        };

        const moveFolderOrDevice = async (targetFolderId) => {
                    moveToFolderModalRef.current?.closeModal();
                    setIsLoading(true);
                    let selectedFolderId = selectedFolderIdRef.current;
                    let selectedDeviceId = selectedDeviceIdRef.current;
                    if(selectedDeviceId === null && selectedFolderId === null) return;
                
                    setKeepConnectData(prevData => {
                        let itemToMove;
                        let updatedData = JSON.parse(JSON.stringify(prevData));
                
                        const removeItemFromItems = (items) => {
                            for (let i = 0; i < items.length; i++) {
                                if (items[i].id === (selectedDeviceId || selectedFolderId)) {
                                    itemToMove = items[i];
                                    items.splice(i, 1);
                                    return true;
                                }
                                if (items[i].type === 'folder' && items[i].items) {
                                    if (removeItemFromItems(items[i].items)) {
                                        return true;
                                    }
                                }
                            }
                            return false;
                        };
                
                        const addItemToItems = (items, targetId) => {
                            for (let i = 0; i < items.length; i++) {
                                if (items[i].id === targetId && items[i].type === 'folder') {
                                    // We are searching the struc with the Item To Be Removed taken out. This will inherently
                                    // also prevent the item from being added to itself or subfolders of itself (if a folder)
                                    items[i].items.unshift(itemToMove);
                                    return true;
                                }
                                if (items[i].type === 'folder' && items[i].items) {
                                    if (addItemToItems(items[i].items, targetId)) {
                                        return true;
                                    }
                                }
                            }
                            console.log('Move operation failed');
                            return false;
                        };
                
                        removeItemFromItems(updatedData);
                        
                        if (targetFolderId === 'root') {
                            updatedData.unshift(itemToMove);
                        } else {
                            if (!addItemToItems(updatedData, targetFolderId)) {
                                // If move operation failed, revert the changes
                                return prevData;
                            }
                        }
                
                        updateServerItems.request(updatedData);
                        return updatedData;
                    });
                
                    setIsLoading(false);
                    selectedDeviceIdRef.current = null;
                    selectedFolderIdRef.current = null;
                };
                        
            // Function to add a new column
        const addColumn = () => {
            setColumns([...columns, { id: `column-${columns.length}`, currentFolder: 'root', folderStack: [] }]);
        };

        // Function to remove a column
        const removeColumn = (columnId) => {
            setColumns(columns.filter(column => column.id !== columnId));
        };

        // Function to update a specific column's current folder
        const updateColumnFolder = (columnId, newFolder, newStack) => {
            setColumns(columns.map(column => 
            column.id === columnId 
                ? { ...column, currentFolder: newFolder, folderStack: newStack } 
                : column
            ));
        };


  // Modified getCurrentFolderItems to work with column-specific folders
  const getCurrentFolderItems = (data, folderId) => {
    if (folderId === 'root') return data;
    const findFolderById = (folders, id) => {
      for (const folder of folders) {
        if (folder.id === id && folder.type === 'folder') return folder;
        if (folder.items && folder.items.length > 0) {
          const result = findFolderById(folder.items, id);
          if (result) return result;
        }
      }
      return null;
    };
    const currentFolder = findFolderById(data, folderId);
    return currentFolder ? currentFolder.items : data;
  };


  const saveCurrentView = async () => {
    try {
        if (!appSettings) {
            console.error('No settings available to save');
            return;
        }

        // Modify the existing settings by adding the current view (columns)
        const updatedSettings = {
            ...appSettings, // Spread existing settings
            savedView: columns, // Add the new saved view
        };

        // Save the updated settings back to the server
        const response = await setAppSettings.request(updatedSettings);
        if (response.ok) {
            console.log('Settings updated successfully!');
        } else {
            console.error('Failed to save settings');
        }
    } catch (error) {
        console.error('Error saving settings:', error);
    }
};

const clearSavedView = async () => {
    try {
        if (!appSettings) {
            console.error('No settings available to clear');
            return;
        }

        // Create a new settings object without the savedView
        const updatedSettings = {
            ...appSettings, // Spread the existing settings
        };

        // Remove the savedView key
        delete updatedSettings.savedView;

        // Send the updated settings back to the server
        const response = await setAppSettings.request(updatedSettings);

        if (response.ok) {
            console.log('Saved view cleared successfully!');
            // Optionally update the state if needed
            setAppSettingsState(updatedSettings); // Update local settings state
        } else {
            console.error('Failed to clear saved view');
        }
    } catch (error) {
        console.error('Error clearing saved view:', error);
    }
};


const loadSavedView = (savedView, keepConnectData) => {
    if (!savedView || !keepConnectData) return;

    const folderExists = (folderId, items) => items.some(item =>
        (item.id === folderId && item.type === 'folder') || 
        (item.type === 'folder' && folderExists(folderId, item.items || []))
    );

    const updatedView = savedView.map(column => {
        // Skip validation for 'root'
        if (column.currentFolder !== 'root' && !folderExists(column.currentFolder, keepConnectData)) {
            console.warn(`Folder ${column.currentFolder} not found, setting to root`);
            return { ...column, currentFolder: 'root', folderStack: [] };
        }

        // Validate folderStack
        const validFolderStack = column.folderStack.filter(folder =>
            folder.id === 'root' || folderExists(folder.id, keepConnectData)
        );

        return { ...column, folderStack: validFolderStack };
    });

    setColumns(updatedView);
};

    

    const handleMoveToFolder = (deviceId) => {
    //setSelectedDeviceId(deviceId);
    // setMoveToFolderModalVisible(true);
    selectedDeviceIdRef.current = deviceId;
    moveToFolderModalRef.current?.openModal();
    };

    const FolderHierarchy = ({ data, onSelect, currentFolderId }) => {
        const renderFolder = (folder, depth = 0) => (
            <View key={folder.id} style={{ marginLeft: depth * 20, paddingVertical: 5 }}>
                {/* <TouchableOpacity onPress={() => onSelect(folder.id)} disabled={folder.id === currentFolderId}> */}
                <TouchableOpacity onPress={() => onSelect(folder.id)}>
                    <View style={[
                        styles.folderContainer,
                        folder.id === currentFolderId && styles.currentFolderContainer
                    ]}>
                        <Foundation name="folder" size={18} color={colors.primary} style={styles.folderIcon} />
                        <Text style={[
                            styles.folderName,
                            folder.id === currentFolderId && styles.currentFolder
                        ]}>
                            {folder.Name}
                        </Text>
                    </View>
                </TouchableOpacity>
                {folder.items && folder.items.map(item =>
                    item.type === 'folder' && renderFolder(item, depth + 1)
                )}
            </View>
        );
    
        return (
            <View>
                {/* <TouchableOpacity onPress={() => onSelect('root')} disabled={currentFolderId === 'root'}> */}
                <TouchableOpacity onPress={() => onSelect('root')}>
                    <View style={[
                        styles.folderContainer,
                        currentFolderId === 'root' && styles.currentFolderContainer
                    ]}>
                        <AntDesign name="home" size={18} color={colors.primary} style={styles.folderIcon} />
                        <Text style={[
                            styles.folderName,
                            // currentFolderId === 'root' && styles.currentFolder
                        ]}>
                            Root
                        </Text>
                    </View>
                </TouchableOpacity>
                {data.map(item => item.type === 'folder' && renderFolder(item, 1))}
            </View>
        );
    };  
    

    const calculateStatusDots = (folder) => { 
    let statusDots = [];
    
    // Recursively check devices and subfolders
    const checkFolder = (items) => {
        items.forEach((item) => {
            if (item.type === "device") {
            moment.tz.setDefault("America/New_York");
            const lastConnect = moment(item.lastConnect, "YYYY-DD-MM HH:mm:ss");
            const isOnline = momentNow.diff(lastConnect, "minutes") <= (item.pollTime/60000 +2);
            statusDots.push(isOnline ? '#33cc33' : "red");
        } else if (item.type === "folder") {
            checkFolder(item.items); // Recursively check subfolders
        }
        });
    };
    
    checkFolder(folder.items);
    
    return statusDots;
    };
    
    const renderStatusDots = (statusDots) => {
        const maxDotsPerRow = 8; // Maximum dots per row
        const firstRow = statusDots.slice(0, maxDotsPerRow); // First row of dots
        const secondRow = statusDots.slice(maxDotsPerRow, maxDotsPerRow * 2); // Second row of dots
        const remainingDots = statusDots.length - maxDotsPerRow * 2; // Count of remaining dots
        
        return (
            <View>
                {/* First row of dots */}
                <View style={styles.statusContainer}>
                    {firstRow.map((color, index) => (
                        <View key={index} style={[styles.statusDot, { backgroundColor: color }]} />
                    ))}
                </View>
                
                {/* Second row of dots */}
                <View style={styles.statusContainer}>
                    {secondRow.map((color, index) => (
                        <View key={index} style={[styles.statusDot, { backgroundColor: color }]} />
                    ))}
                </View>
    
                {/* Display remaining dots count on a separate row if there are more than 16 dots */}
                {remainingDots > 0 && (
                    <View style={styles.extraDotsContainer}>
                        <Text style={styles.extraDotsText}>+{remainingDots} more</Text>
                    </View>
                )}
            </View>
        );
    };    
    
    

    // Update renderItemOrFolder to handle folder navigation
    const renderItemOrFolder = ({ item, drag, isActive, navigateToFolder, columnId }) => {
        if (item.type === 'folder') {
            const statusDots = calculateStatusDots(item);
            
            return (
                <ScaleDecorator>
                    <View
                        style={[
                            isActive ? styles.item3 : styles.item3,
                            { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
                        ]}
                    >
                        <TouchableOpacity
                            style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}
                            onPress={() => navigateToFolder(item)}
                            onLongPress={drag}
                        >
                            <Foundation name="folder" size={24} color={colors.primary} />
                            <Text style={[styles.TextStyleTitle, { marginLeft: 10 }]}>{item.Name}</Text>
                            
                            {renderStatusDots(statusDots)}
                        </TouchableOpacity>
                        
                        <TouchableOpacity onPress={() => modifyFolderModalRef.current.openModal(item.id, item.Name)}>
                            <View style={{ padding: 10 }}>
                                <MaterialCommunityIcons name="dots-vertical" size={24} color={colors.primary} />
                            </View>
                        </TouchableOpacity>
                    </View>
                </ScaleDecorator>
            );
        } else {
            return renderItem({ item, drag, isActive, columnId });
        }
    }; 

    const renderItem = ({ item, drag, isActive }) => {
        moment.tz.setDefault("America/New_York");
        var then = moment(item.lastConnect, "YYYY-DD-MM HH:mm:ss");
        var isRebooter = item.powerPal == "0";
        const data = JSON.parse(item?.realtimeData);
        // Determine the image source based on item type
        let imageSource;
        let typeName;
        let isPowerPal = false;
        let isWaterPal = false;
        let isClimatePal = false;
        switch (item.powerPal) {
            case "0":
                imageSource = require('../assets/kcIcon.png'); // Keep Connect
                break;
            case "1":
                imageSource = require('../assets/ppIcon.png'); // PowerPal
                typeName = "Power Pal";
                isPowerPal = true;
                break;
            case "2":
                imageSource = require('../assets/wpIcon.png'); // WaterPal
                typeName = "Water Pal";
                isWaterPal = true;
                break;
            case "3":
                imageSource = require('../assets/cpIcon.png'); // ClimatePal
                typeName = "Climate Pal";
                isClimatePal = true;
                break;
            default:
                imageSource = require('../assets/kcIcon.png'); // Default to Keep Connect if type is unknown
                isRebooter = true;
                break;
        }

        let isOnline = momentNow.diff(then, "minutes") <= (item.pollTime/60000 +2);
        let realtimeData = JSON.parse(item.realtimeData);
        let isHeartBeat = item.offlineDelay > 0;

        return (
          <ScaleDecorator>
            <View style={isActive ? styles.item : styles.item2}>
            <TouchableOpacity
              onPress={()=>navigation.navigate(routes.DEVICE, {item: item, changedDevices: setUpdateNeeded})}
              onLongPress={drag}
            >
            <View style={{paddingTop: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyleTitle}>{item.Name}</Text></View><View style={{flex: 1}}><Text style={styles.TextStyleRightItem}>Status: {isOnline ? <Text style={{color: '#33cc33'}}>Online</Text> : <Text style={{color: "red"}}>Offline</Text>}</Text></View></View>
            {isRebooter && <View style={{paddingTop: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>Web ID: {item.WebID}</Text></View><View style={{flex: 1}}><Text style={styles.TextStyleRightItem}>No. Resets: {item.resets}</Text></View></View>}
            {isRebooter && <View style={{paddingVertical: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>Mode: {item.follower == "true" ? "Follower" : "Master"}</Text></View><View style={{flex: 1}}><Text style={styles.TextStyle}></Text></View></View>}
            {!isRebooter && <View style={{paddingTop: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>Web ID: {item.WebID}</Text></View><View style={{flex: 1}}><Text style={styles.TextStyleRightItem}>No. Events: {item.resets}</Text></View></View>}
            {isPowerPal && <View style={{paddingVertical: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>Power: {realtimeData?.P || 0} W</Text></View><View style={{flex: 1}}><Text style={styles.TextStyle}></Text></View></View>}
            {isWaterPal && <View style={{paddingVertical: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>{item.water == 1 ? "Water Detected" : "No Water Detected"}</Text></View><View style={{flex: 1}}><Text style={styles.TextStyleRightItem}></Text></View></View>}
            {isWaterPal && <View style={{paddingVertical: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>Battery: {item.batt}%</Text></View><View style={{flex: 1}}><Text style={styles.TextStyleRightItem}></Text></View></View>}
            {isClimatePal && <View style={{paddingVertical: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>Temp: {item.temp}</Text></View><View style={{flex: 1}}><Text style={styles.TextStyleRightItem}>Humidity: {item.humidity}%</Text></View></View>}
            {isClimatePal && <View style={{paddingVertical: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>Battery: {item.batt}%</Text></View><View style={{flex: 1}}><Text style={styles.TextStyle}></Text></View></View>}
            <View style={{justifyContent: "center", paddingVertical: 2}}>
            <Image
                source={imageSource} // Adjust the path to your image file
                style={styles.imageStyle}
            />
            </View>
            <ErrorMessage2
                error={"Firmware Update Required"}
                visible={!(item.firmware.includes("KeepConnect") || !isRebooter)}
                />
            </TouchableOpacity>
            <View style={{paddingVertical: 0, flexDirection: "row"}}><View style={{ paddingVertical: 0, flexDirection: "row", alignItems: "center" }}>
                {isOnline && realtimeData && <Foundation 
                    name="power" 
                    size={20} 
                    color={realtimeData?.state == "1" ? "#33cc33" : "red"} 
                    style={{ marginRight: 8 }} // Add some spacing between the icons
                    />}
                <TouchableOpacity 
                    onPress={() => navigation.navigate(routes.MESSAGESETTINGS, { item,  changedDevices: setUpdateNeeded})}
                    >
                <MaterialCommunityIcons 
                    name={isHeartBeat ? "heart-pulse" : "heart-off"} 
                    size={20} 
                    color={isHeartBeat ? "#33cc33" : "gray"} 
                />
                </TouchableOpacity>
                </View>
                <View style={{flex: 1}}><Text style={styles.TextStyle}></Text></View>
            <View style={{justifyContent: "flex-end", padding: 10, paddingRight: 25}}>
            <TouchableOpacity 
                onPress={() => handleMoveToFolder(item.id)}
                >
                <MaterialCommunityIcons name="folder-move-outline" size={24} color={colors.primary} />
            </TouchableOpacity>
            </View>
            </View>
            </View>
          </ScaleDecorator>
        );
    };

    return (
        <Screen navigation={navigation} isLoading={isLoading} setUpdateNeeded={setUpdateNeeded} keepConnects={keepConnectData} showSettingsIcon={isSubscribed}>
            {isLoading ? (
                <View style={styles.loadingContainer}>
                    <ActivityIndicator size="large" color={colors.primary} />
                </View>
            ) : (
                <View style={styles.mainContainer}>
                    <ErrorMessage error={requestMessage} visible={requestFailed} />
                    <ErrorMessage error={serverMessage} visible={errorFromServer} />
                    {isSubscribed ? (
                        <React.Fragment>
                            <View style={styles.summaryContainer}>
                                {/* <Text style={styles.summaryText}>Summary of Devices</Text> */}
                                {Platform.OS === 'web' && <View style={styles.summaryIconsContainer}>
                                    <TouchableOpacity onPress={addColumn} style={styles.summaryIcon}>
                                        <MaterialCommunityIcons name="table-column-plus-after" size={24} color={colors.primary} />
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={saveCurrentView} style={styles.summaryIcon}>
                                        <MaterialCommunityIcons name="pin" size={24} color={colors.primary} />
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={clearSavedView} style={styles.summaryIcon}>
                                        <MaterialCommunityIcons name="pin-off" size={24} color={colors.primary} />
                                    </TouchableOpacity>
                                    {Platform.OS !== 'ios' && (
                                        <TouchableOpacity onPress={() => setUpdateNeeded(true)} style={styles.summaryIcon}>
                                            <MaterialIcons name="refresh" size={24} color={colors.primary} />
                                        </TouchableOpacity>
                                    )}
                                    {!isDesktop && (
                                        <Popable
                                            backgroundColor="#4eb9d6"
                                            caret={false}
                                            content="If you have multiple Keep Connects, try holding item to enable drag / reorder"
                                        >
                                            <Foundation name="lightbulb" size={24} color="#4eb9d6" />
                                        </Popable>
                                    )}
                                </View>}
                            </View>
                            <NestableScrollContainer horizontal={Platform.OS === 'web'} containerStyle={{flex: 1}}>
                                {columns.map((column, index) => (
                                    <View key={column.id} style={styles.columnWrapper}>
                                        <ColumnView
                                            data={getCurrentFolderItems(keepConnectData, column.currentFolder)}
                                            column={column}
                                            updateColumnFolder={updateColumnFolder}
                                            renderItemOrFolder={renderItemOrFolder}
                                            loadKeepConnects={loadKeepConnects}
                                            refreshing={refreshing}
                                            handleCreateNewFolder={handleCreateNewFolder}
                                            navigation={navigation}
                                            setUpdateNeeded={setUpdateNeeded}
                                            removeColumn={removeColumn}
                                            isMainColumn={index === 0}
                                            setNewServerOrder={setNewServerOrder}
                                        />
                                    </View>
                                ))}
                            </NestableScrollContainer>
                        </React.Fragment>
                    ) : (
                        <View style={styles.noServiceContainer}>
                            <Text style={styles.noServiceText}>No Active Service</Text>
                            <Text style={styles.noServiceDescription}>This User Account does not have active service. Please use Keep Connect website to manage your account.</Text>
                            <Button
                                title="Retry"
                                onPress={() => loadKeepConnects()}
                            />
                            <Button
                                title="Log Out"
                                onPress={() => auth.logOut()}
                            />
                        </View>
                    )}
                </View>
            )}
            {(!isLoading && Platform.OS !== 'web') && (
                <View style={styles.footerContainer}>
                    <Text style={styles.footerText} onPress={() => Linking.openURL('mailto: support@johnson-creative.com')}>
                        Support
                    </Text>
                    <Text style={styles.footerText} onPress={() => Linking.openURL('https://www.johnson-creative.com/keep-connect-cloud-services-mobile-app-privacy-policy/')}>
                        Privacy
                    </Text>
                </View>
            )}
            <Modal ref={newFolderModalRef} transparent={true} animationType="slide" onBackdropPress={() => newFolderModalRef.current.closeModal()}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Enter new folder name:</Text>
                        <TextInput 
                            inputRef={newFolderNameRef}
                            placeholder="Folder name"
                            style={styles.modalInput}
                        />
                        <Button title="Create" onPress={confirmCreateNewFolder} />
                        <Button title="Cancel" onPress={() => newFolderModalRef.current.closeModal()} />
                    </View>
                </View>
            </Modal>
            <Modal ref={modalRef} transparent={true} animationType="slide" onBackdropPress={() => modalRef.current.closeModal() }>
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Are you sure you want to delete this folder?</Text>
                        <Button title="Delete" onPress={confirmDeleteFolder} />
                        <Button title="Cancel" onPress={() => modalRef.current.closeModal()} />
                    </View>
                </View>
            </Modal>
            <Modal 
                ref={moveToFolderModalRef}
                onBackdropPress={() => moveToFolderModalRef.current?.closeModal()}
            >
                <View style={styles.modalContent}>
                    <Text style={styles.modalTitle}>Select a folder to move the device to:</Text>
                    <ScrollView style={styles.folderScrollView}>
                        <FolderHierarchy 
                            data={keepConnectData} 
                            onSelect={moveFolderOrDevice}
                            currentFolderId={columns.currentFolder}
                        />
                    </ScrollView>
                    <Button title="Cancel" onPress={() => moveToFolderModalRef.current?.closeModal()} />
                </View>
            </Modal>
            <ModifyFolderModal ref={modifyFolderModalRef} handleModifyFolder={handleModifyFolder} />
        </Screen>
    );
}

const styles = StyleSheet.create({
    mainContainer: {
        flex: Platform.OS === 'web' ? 1 : .85,
        width: '100%',
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    summaryContainer: {
        padding: 10,
        marginTop: 20,
        borderBottomWidth: 1,
        borderBottomColor: colors.light,
    },
    summaryText: {
        fontWeight: 'bold',
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 10,
    },
    summaryIconsContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    summaryIcon: {
        marginHorizontal: 10,
    },
    columnsScrollView: {
        flex: 1,
    },
    columnWrapper: {
        width: 300, // Adjust as needed
        borderRightWidth: 1,
        borderRightColor: colors.light,
    },
    noServiceContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    noServiceText: {
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: 10,
    },
    noServiceDescription: {
        textAlign: 'center',
        marginBottom: 20,
    },
    footerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        borderTopWidth: 1,
        borderTopColor: colors.light,
    },
    footerText: {
        color: colors.primary,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 22,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        borderColor: 'rgba(0, 0, 0, 0.1)',
        maxHeight: '80%', // Limit the height of the modal
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 15,
        textAlign: 'center',
    },
    folderScrollView: {
        maxHeight: 300, // Adjust this value as needed
        width: '100%',
    },
    folderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: colors.light,
    },
    currentFolderContainer: {
        backgroundColor: colors.light,
    },
    folderIcon: {
        marginRight: 10,
    },
    folderName: {
        fontSize: 16,
    },
    currentFolder: {
        fontWeight: 'bold',
    },
    modalInput: {
        borderWidth: 1,
        borderColor: colors.light,
        borderRadius: 4,
        padding: 10,
        marginBottom: 10,
        width: '100%',
    },
    TextStyleLink: {
        color: colors.primary,
    },
    item: {
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#4eb9d6',
        padding: 10,
        marginVertical: 8,
        marginHorizontal: 16,
        backgroundColor: "#fff",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
    },
    item2: {
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#4eb9d6',
        padding: 10,
        paddingBottom: 0,
        marginVertical: 8,
        marginHorizontal: 16,
        backgroundColor: "#fff",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
    },
    item3: {
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#4eb9d6',
        padding: 10,
        marginVertical: 8,
        marginHorizontal: 16,
        backgroundColor: "#fff",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
    },
    TextStyle: {
        color: "black",
        alignSelf: "flex-start",
        paddingLeft: 5,
    },
    TextStyleTitle: {
        color: "black",
        fontWeight: "600",
        fontSize: 16,
    },
    TextStyleRightItem: {
        color: "black",
        alignSelf: "flex-end",
        paddingRight: 5,
    },
    imageStyle: {
        position: 'absolute',
        bottom: 0,
        right: 5,
        width: 80,
        height: 20,
    },
    innerBox: {
        marginLeft: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: 60,
    },
    statusDot: {
        width: 12,
        height: 12,
        borderRadius: 6,
        marginHorizontal: 4,
        borderWidth: 1,
        borderColor: "#ddd",
    },
    columnHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: colors.light,
    },
    headerIcon: {
        marginHorizontal: 5,
    },
    breadcrumbContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: colors.light,
    },
    currentFolderText: {
        fontWeight: 'bold',
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 5,
    },
    backButtonContainer: {
        marginTop: 5,
    },
    backButton: {
        color: colors.primary,
        fontSize: 14,
    },
    statusContainer: {
        flexDirection: 'row', // Arrange dots in a row
        alignItems: 'center',
        marginLeft: 10, // Adjust spacing as needed
        marginVertical: 2, // Add some vertical spacing between rows
    },
    statusDot: {
        width: 8,
        height: 8,
        borderRadius: 6,
        marginHorizontal: 2,
        borderWidth: 1,
        borderColor: "#ddd",
    },
    extraDotsContainer: {
        marginLeft: 10, // Align with the dots
        marginTop: 4, // Add a small gap between the dots and the extra count
    },
    extraDotsText: {
        fontSize: 12,
        color: '#999',
    },
});

export default OverviewScreen;