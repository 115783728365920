import React, { useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import AppLoading from 'expo-app-loading';
import { Platform } from "react-native";

import navigationTheme from "./jsCoreKCCS/navigation/navigationTheme";
import AppNavigator from "./jsCoreKCCS/navigation/AppNavigator";
//import OfflineNotice from "./components/OfflineNotice";
import AuthNavigator from "./jsCoreKCCS/navigation/AuthNavigator";
import AuthContext from "./jsCoreKCCS/auth/context";
import authStorage from "./jsCoreKCCS/auth/storage"; 
import MQTTProvider from "./jsCoreKCCS/components/MQTTContext";

if (Platform.OS === 'web' && typeof setImmediate === 'undefined') { //this was added when DraggableFlatlist was upgraded to latest which uses Reanimated V2 where setImmediate is not availabe in web.
  global.setImmediate = (fn, ...args) => setTimeout(fn, 0, ...args);
}


export default function App() {
  const [user, setUser] = useState();
  const [isReady, setIsReady] = useState(false);
  const [userPermissions, setUserPermissions] = useState({}); //sample {license: "basic", role: "admin"}
  const [mqttParams, setMqttParams] = useState({}); //sample {license: "basic", role: "admin"}

  const restoreUser = async () => {
    const user = await authStorage.getUser();
    if (user) setUser(user);
  };

  if (!isReady)
    return (
      <AppLoading startAsync={(restoreUser)} onError={()=>setIsReady(true)} onFinish={() => setIsReady(true)} />
    );

  return (
    <AuthContext.Provider value={{ user, setUser, userPermissions, setUserPermissions, setMqttParams, mqttParams }}>
      <MQTTProvider>
      <NavigationContainer theme={navigationTheme}>
        {user ? <AppNavigator /> : <AuthNavigator />}
      </NavigationContainer>
      </MQTTProvider>
    </AuthContext.Provider>
  );
}