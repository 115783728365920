import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, ActivityIndicator, FlatList, TouchableOpacity, TextInput, Platform, ScrollView, Dimensions } from "react-native";
import Screen from '../components/Screen';
import Dropdown from '../components/Dropdown';
import Button from '../components/Button';
import useApi from "../hooks/useApi";
import keepConnectApi from '../api/keepConnectsApi';
import colors from '../config/colors';
import { ErrorMessage } from '../components/forms';
import PlatformModal from '../components/PlatformModal';
import routes from "../navigation/routes";

const { width: screenWidth } = Dimensions.get('window'); // Get screen width

function UserManagementScreen({ navigation }) {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [requestFailed, setRequestFailed] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [accountType, setAccountType] = useState("subaccount");
    const [role, setRole] = useState("read_only");
    const [userAction, setUserAction] = useState("attach_existing");
    const [generatePassword, setGeneratePassword] = useState(true);

    const getEnterpriseAccounts = useApi(keepConnectApi.getEnterpriseAccounts);
    const addUserAccount = useApi(keepConnectApi.addUserAccount);
    const createAndAddUserAccount = useApi(keepConnectApi.createAndAddUserAccount);
    const removeUserAccount = useApi(keepConnectApi.removeUserAccount);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        setIsLoading(true);
        const response = await getEnterpriseAccounts.request();
        if (!response.ok) {
            setRequestFailed(true);
            setIsLoading(false);
            return;
        }
        setUsers(response.data.accounts);
        setRequestFailed(false);
        setIsLoading(false);
    };

    const handleAddUser = async () => {
        setIsLoading(true);
        let response;
        if (userAction === "attach_existing") {
            response = await addUserAccount.request(email, accountType, role);
        } else {
            const pass = generatePassword ? undefined : password;
            response = await createAndAddUserAccount.request(email, username, pass, accountType, role);
        }
        if (!response.ok) {
            setRequestFailed(true);
            setIsLoading(false);
            return;
        }
        await loadUsers();
        setModalVisible(false);
        resetForm();
    };

    const handleEditUser = async () => {
        setIsLoading(true);
        const response = await addUserAccount.request(email, accountType, role);
        if (!response.ok) {
            setRequestFailed(true);
            setIsLoading(false);
            return;
        }
        await loadUsers();
        setModalVisible(false);
        resetForm();
    };

    const handleDeleteUser = async (userId) => {
        setIsLoading(true);
        const response = await removeUserAccount.request(userId);
        if (!response.ok) {
            setRequestFailed(true);
            setIsLoading(false);
            return;
        }
        await loadUsers();
        setModalVisible(false);
    };

    const resetForm = () => {
        setEmail("");
        setUsername("");
        setPassword("");
        setAccountType("subaccount");
        setRole("read_only");
        setUserAction("attach_existing");
        setGeneratePassword(true);
        setSelectedUser(null);
    };

    const renderUserItem = ({ item }) => (
        <TouchableOpacity
            style={styles.userItem}
            onPress={() => {
                setSelectedUser(item);
                setEmail(item[0]);
                setAccountType(item[2]);
                setRole(item[3]);
                setModalVisible(true);
            }}
        >
            <Text style={[styles.userItemText, styles.emailColumn]} numberOfLines={1} ellipsizeMode="tail">{item[0]}</Text>
            <Text style={[styles.userItemText, styles.typeColumn]}>{item[2] == 'admin' ? "Admin" : "Sub\nAccount"}</Text>
            <Text style={[styles.userItemText, styles.roleColumn]}>{item[3]}</Text>
        </TouchableOpacity>
    );

    const renderHeader = () => (
        <View style={styles.headerRow}>
            <Text style={[styles.headerText, styles.emailColumn]}>Email</Text>
            <Text style={[styles.headerText, styles.typeColumn]}>Account Type</Text>
            <Text style={[styles.headerText, styles.roleColumn]}>Role</Text>
        </View>
    );

    return (
        <Screen style={styles.screen}>
            <Text style={styles.title}>User Management</Text>
            <ErrorMessage error="Unable to Communicate With Server" visible={requestFailed} />
            {isLoading ? (
                <View style={styles.loader}>
                    <ActivityIndicator size="large" color={colors.primary} />
                </View>
            ) : (
                <View style={styles.listContainer}>
                    <FlatList
                        ListHeaderComponent={renderHeader}
                        data={users}
                        keyExtractor={(item) => item[1].toString()}
                        renderItem={renderUserItem}
                        style={styles.list}
                        contentContainerStyle={styles.listContent} 
                    />
                    <Button title="Add New User" onPress={() => {
                        resetForm();
                        setModalVisible(true);
                    }} />
                </View>
            )}

            <PlatformModal isVisible={modalVisible} animationType="slide"  onBackdropPress={() => setModalVisible(false)}>
                    
                    <ScrollView contentContainerStyle={styles.modalContent}>
                        <Text style={styles.modalTitle}>{selectedUser ? "Edit User" : "Add New User"}</Text>

                        {!selectedUser && (
                            <View style={styles.dropdownContainer}>
                                <Dropdown
                                    labelText="User Action"
                                    defaultValueProp={userAction}
                                    itemsArray={[
                                        { label: "Attach Existing User", value: "attach_existing" },
                                        { label: "Create and Attach New User", value: "create_new" }
                                    ]}
                                    onChangeItemProp={setUserAction}
                                />
                            </View>
                        )}

                        <TextInput
                            style={styles.input}
                            placeholder="Email"
                            value={email}
                            onChangeText={setEmail}
                            editable={!selectedUser}
                        />

                        {!selectedUser && userAction === "create_new" && (
                            <>
                                <TextInput
                                    style={styles.input}
                                    placeholder="Username"
                                    value={username}
                                    onChangeText={setUsername}
                                />
                                <View style={styles.dropdownContainer}>
                                    <Dropdown
                                        labelText="Password Option"
                                        defaultValueProp={generatePassword ? "generate" : "create"}
                                        itemsArray={[
                                            { label: "Generate Password and Email User", value: "generate" },
                                            { label: "Create Password", value: "create" }
                                        ]}
                                        onChangeItemProp={(value) => setGeneratePassword(value === "generate")}
                                    />
                                </View>
                                {!generatePassword && (
                                    <TextInput
                                        style={styles.input}
                                        placeholder="Password"
                                        value={password}
                                        onChangeText={setPassword}
                                        secureTextEntry
                                    />
                                )}
                            </>
                        )}

                        <View style={styles.dropdownContainer}>
                            <Dropdown
                                labelText="Account Type"
                                defaultValueProp={accountType}
                                itemsArray={[
                                    { label: "Subaccount", value: "subaccount" },
                                    { label: "Agency", value: "agency" }
                                ]}
                                onChangeItemProp={setAccountType}
                                onTop={true}
                            />
                        </View>
                        <View style={styles.dropdownContainer}>
                            <Dropdown
                                labelText="Role"
                                defaultValueProp={role}
                                itemsArray={[
                                    { label: "Read Only", value: "read_only" },
                                    { label: "Admin", value: "admin" }
                                ]}
                                onChangeItemProp={setRole}
                            />
                        </View>
                        {selectedUser ? (
                            <>
                                {accountType === 'subaccount' && <Button
                                    title="Manage Devices"
                                    onPress={() =>  {setModalVisible(false); navigation.navigate(routes.SUBACCOUNTDEVICES, { subaccountId: selectedUser[1]  })}}
                                />}
                                <Button
                                    title="Save Changes"
                                    onPress={handleEditUser}
                                />
                                <Button
                                    title="Delete User"
                                    onPress={() => handleDeleteUser(selectedUser[1])}
                                />
                            </>
                        ) : (
                            <Button
                                title="Add User"
                                onPress={handleAddUser}
                            />
                        )}
                        <Button title="Cancel" onPress={() => setModalVisible(false)} />
                    </ScrollView>
            </PlatformModal>
        </Screen>
    );
}

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        alignItems: 'stretch',
        paddingHorizontal: 15,
    },
    title: {
        paddingTop: 20,
        fontWeight: "bold",
        alignSelf: "center",
        fontSize: 24,
        marginBottom: 20,
    },
    loader: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    listContainer: {
        flex: 1,
        marginBottom: 20,
    },
    list: {
        borderWidth: 1,
        borderColor: colors.medium,
        borderRadius: 10,
        paddingVertical: 10,
        marginBottom: 20,
    },
    listContent: {
        paddingHorizontal: 15,
    },
    headerRow: {
        flexDirection: 'row',
        padding: 15,
        backgroundColor: colors.light,
        borderBottomWidth: 1,
        borderBottomColor: colors.medium,
    },
    headerText: {
        fontWeight: 'bold',
        fontSize: 18,
    },
    userItem: {
        flexDirection: 'row',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: colors.light,
        backgroundColor: 'white',
    },
    userItemText: {
        fontSize: 18,
    },
    emailColumn: {
        flex: 2.5,
        marginRight: 10,
    },
    typeColumn: {
        flex: 2,
        marginRight: 10,
    },
    roleColumn: {
        flex: 2,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    modalContent: {
        flexGrow: 1,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 20,
        alignItems: "stretch",
        justifyContent: "center",
        alignSelf: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: '95%', // Increased from 90% to 95% to make it wider
        maxWidth: '95%',  // Removed the fixed maxWidth constraint
    },
    modalTitle: {
        marginBottom: 20,
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 22,
    },
    input: {
        height: 50,
        borderWidth: 1,
        borderColor: colors.medium,
        borderRadius: 10,
        padding: 15,
        marginBottom: 20,
        fontSize: 16,
    },
    dropdownContainer: {
        marginBottom: 20,
        zIndex: Platform.OS === 'ios' ? 1000 : undefined,
    },
});

export default UserManagementScreen;
