import { useContext } from "react";
import jwtDecode from "jwt-decode";

import AuthContext from "./context";
import authStorage from "./storage";

 const useAuth = () => {
  const { user, setUser, userPermissions, setUserPermissions, setMqttParams, mqttParams } = useContext(AuthContext);
  
  const logIn = (authToken) => {
    const user = jwtDecode(authToken);
    authStorage.storeToken(authToken);
    setUser(user);
  };

  const logOut = () => {
    setUser(null);
    authStorage.removeToken();
  };

  return { user, logIn, logOut, userPermissions, setUserPermissions, setMqttParams, mqttParams };
};

export default useAuth;
