import React from "react";
import { TouchableOpacity, StyleSheet, Text, View } from "react-native";
import { MaterialIcons } from '../components/PlatformSpecificIcons';
import { Popable } from 'react-native-popable';

//import Text from "./Text";

function PopShowMessage({ children, style, ...otherProps }) {
  return (
    
    <Popable 
    backgroundColor="#4eb9d6"
    content={children}
    style={{width:200}}
    caret={false}>
    <View style={{flexDirection: "row", justifyContent: "center"}}>
    <MaterialIcons name="sms" size={24} color="#4eb9d6" />
    <Text style={{color:"#4eb9d6"}}>Show Default Message</Text>
    </View>
  </Popable>
  );
}

const styles = StyleSheet.create({
  text: {
    padding: 20,
  },
});

export default PopShowMessage;