import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, ActivityIndicator } from "react-native";
import * as Yup from "yup";

import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
} from "../components/forms";

import Screen from '../components/Screen';
import alert from '../components/alert';
import colors from "../config/colors";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import Modal from '../components/PlatformModal';
import Button from '../components/Button';

function AddKeepConnectScreen({ route, navigation }) {
    const { folderId } = route.params;
    const addKeepConnectApi = useApi(keepConnectApi.registerKeepConnect);  
    const [isLoading, setIsLoading] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false);
    const [requestMessage, setRequestMessage] = useState("Error Communicating with Server");
    const [errorFromServer, setErrorFromServer] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [visibleTrigger, setVisibleTrigger] = useState(false);

    const validationSchema = Yup.object().shape({
      webID: Yup.string().required().max(5).label("Web ID"),
      code: Yup.string().required().length(17).label("Keep Connect Code"),
      name: Yup.string().required().label("Name"),
    });

    useEffect(() => {
      if(route.params.initialSetup === true) setVisibleTrigger(true);
    }, []);

    const handleSubmit = async ({ webID, code, name }) => {
        setIsLoading(true);
        const response = await addKeepConnectApi.request(webID, code, name, folderId);
        if (!response.ok) {
          setIsLoading(false);
          setRequestFailed(true);
          setRequestMessage("Failed to add device. Please try again.");
          return;
        } 
        if (!response.data || !response.data.MSG) {
          setIsLoading(false);
          setErrorFromServer(true);
          setServerMessage("Unknown Server Error");
          return;
        }
        if (response.data.MSG !== "Success!") {
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        alert(
          "Success!",
          "Your Keep Connect was successfully registered!",
          [
            { 
              text: "OK", 
              onPress: () => {
                route.params.changedDevices(true);
                navigation.goBack();
              } 
            }
          ]
        );
    };

    return (
      <Screen style={styles.container}>
        {isLoading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color={colors.primary}/>
          </View>
        ) : (
          <><View style={styles.formContainer}>
            <Text style={styles.title}>Add New Device</Text>
            <ErrorMessage error={requestMessage} visible={requestFailed} />
            <ErrorMessage error={serverMessage} visible={errorFromServer} />
            <Form
              initialValues={{ webID: "", code: "", name: "" }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name="webID"
                keyboardType="numeric"
                placeholder="Keep Connect Web ID"
                textContentType="none"
                helpText="The Web ID can be found at the end of any SMS/Email notification received from your Keep Connect device."
              />
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name="code"
                placeholder="Keep Connect Code"
                textContentType="none"
                helpText="The Keep Connect Code is the MAC Address of the unit and can be found in SMS/Email notifications or router logs."
              />
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                name="name"
                placeholder="Nickname"
                textContentType="none"
                helpText="Assign a memorable name to your unit."
              />
              <SubmitButton title="Add Keep Connect" />
            </Form>
          </View>
          <Modal isVisible={visibleTrigger} transparent={true} animationType = {"slide"}>
              <View style={{height: 350,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
              <View style={{width:300}}>
              <Text style={{fontWeight: "bold", alignSelf: "center"}}>Hello and Welcome!</Text><Text></Text>
              <Text style={{alignSelf: "center"}}>To get you started, you will just need to register one or more devices with our platform. Please use the next screen to enter your Web ID and Registration Code to get started.</Text><Text></Text>
              <Text style={{alignSelf: "center"}}>Hint: Your Web ID and Registration Code can be found at the end of any notification you recieved from your Keep Connect during the device setup. For further assistance please contact our support team.</Text>
              <Text></Text>
              <Button title="Continue" onPress={()=>setVisibleTrigger(false)} />
              </View>
              </View>
          </Modal>
          </>
        )}
      </Screen>
    );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    alignSelf: "center",
    marginBottom: 20,
  },
});

export default AddKeepConnectScreen;