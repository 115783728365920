import React from "react";
import { ImageBackground, StyleSheet, View, Image, Text, Linking } from "react-native";
import colors from '../config/colors'

import Button from "../components/Button";
import routes from "../navigation/routes";

function WelcomeScreen({ navigation }) {
  return (
    <View
      style={styles.background}
    >
      <View style={styles.logoContainer}>
        <Image style={styles.logo} source={require("../assets/KeepConnectlogoblankback.png")} />
        <Text style={styles.tagline}>Always Connected</Text>
        <View style={styles.buttonsContainer}>
          <Button
            title="Login"
            onPress={() => navigation.navigate(routes.LOGIN)}
          />
        </View>
      </View>
        <View style={{width: '100%', justifyContent: 'center', alignItems: 'center',position: "absolute", bottom: 0}}>
        <View style={{flexDirection: "row", paddingBottom: 20}}><View style={{flex: 1}}><Text style={styles.TextStyle} onPress={ ()=> Linking.openURL('mailto: support@johnson-creative.com') } >Support</Text></View><View style={{flex: 1}}></View><View style={{flex: 1}}><Text style={styles.TextStyle} onPress={ ()=> Linking.openURL('https://www.johnson-creative.com/keep-connect-cloud-services-mobile-app-privacy-policy/') } >Privacy</Text></View></View>
        </View>
    </View>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonsContainer: {
    paddingVertical: 80,
    width: "100%",
  },
  logo: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
  },
  logoContainer: {

    alignItems: "center",
    justifyContent: "center",
  },
  tagline: {
    fontSize: 25,
    fontWeight: "600",
    paddingVertical: 60,
    justifyContent: "center",
  },
  TextStyle: {
    color: colors.primary,
    alignSelf: "center",
 }
});

export default WelcomeScreen;
