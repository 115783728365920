import React from "react";
import { View, Text, Switch } from "react-native";
import colors from "../../config/colors";
import { useFormikContext } from "formik";

function AppSwitch({ name, formikOut, labelText, isDisabled }) {
    const {
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        values,
      } = useFormikContext();

return (
  <View style={{alignItems:"flex-start", paddingTop: 20, paddingBottom: 20}}>
    <Text>{labelText}</Text>
    <Switch 
    trackColor={{ false: "#767577", true: colors.primary }}
    thumbColor={values[name] ? "#fafafa" : colors.primary}
    onValueChange={(value) => {
        setFieldValue(name, value);
        setFieldTouched(name);
        formikOut && formikOut(value);
    }}
    value={values[name]}
    disabled={isDisabled}
  />
  </View>
  );
}

export default AppSwitch;