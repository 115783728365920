import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import Modal from 'react-native-modal';

// Wrapping the component with forwardRef
const PlatformModal = forwardRef(({ isVisible, onBackdropPress, children, ...otherProps }, ref) => {
  // Manage the modal visibility state
  const [internalVisible, setInternalVisible] = useState(isVisible);

  // Sync internalVisible with the isVisible prop when it changes
  useEffect(() => {
    if (isVisible !== undefined) {
      setInternalVisible(isVisible);
    }
  }, [isVisible]);

  // Expose methods to parent component via the ref
  useImperativeHandle(ref, () => ({
    openModal: () => setInternalVisible(true),
    closeModal: () => setInternalVisible(false),
  }));

  return (
    <Modal
      isVisible={internalVisible}  // Use the internal state or isVisible prop
      onBackdropPress={() => {
        if (onBackdropPress) onBackdropPress();
        setInternalVisible(false);  // Automatically close modal on backdrop press
      }}
      {...otherProps}
    >
      {children}
    </Modal>
  );
});

// Keep backward compatibility with default props
PlatformModal.defaultProps = {
  isVisible: false,
};

export default PlatformModal;
