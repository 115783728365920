import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, ActivityIndicator, Alert } from "react-native";
import * as Yup from "yup";
import {
  Form,
  FormField,
  LabelFormField,
  SubmitButton,
} from "../components/forms";
import Dropdown from '../components/forms/FormDropdown';
import MultiSelect from '../components/forms/FormMultiSelect';
import Switch from '../components/forms/FormSwitch';
import Button from '../components/Button';
import Screen from '../components/Screen';
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import colors from "../config/colors";
import DateTimePickerWrapper from "../components/DateTimePickerWrapper";  // Use the wrapper
import keepConnectStore from '../auth/keepConnectStorage';



function ScheduleEventScreen({ route }) {
  const getScheduleApi = useApi(keepConnectApi.getScheduledEvent);
  const saveScheduleApi = useApi(keepConnectApi.setScheduledEvent);
  const [appTimezone, setAppTimeZone] = useState("America/New_York");
  const [eventTime, setEventTime] = useState(new Date());
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState("none");
  const [recurrenceInterval, setRecurrenceInterval] = useState(1);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [monthlyDay, setMonthlyDay] = useState(13);
  const [yearlyMonth, setYearlyMonth] = useState(1);
  const [yearlyDay, setYearlyDay] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleExists, setScheduleExists] = useState(false);

  const validationSchema = Yup.object().shape({
  isRecurring: Yup.boolean(),
  recurrenceType: Yup.string()
    .required("Recurrence type is required")
    .label("Recurrence Type"),

  recurrenceInterval: Yup.number()
    .transform((value, originalValue) => {
      const parsed = Number(originalValue);
      return isNaN(parsed) ? undefined : parsed;
    })
    .typeError("Recurrence interval must be a number")
    .when("isRecurring", {
      is: true,
      then: Yup.number()
        .required("Recurrence interval is required")
        .min(1, "Interval must be at least 1"),
      otherwise: Yup.number().nullable(),
    }),

    daysOfWeek: Yup.array()
    .of(Yup.string())
    .label("Days of the Week")
    .when("recurrenceType", {
      is: "weekly",
      then: Yup.array()
        .min(1, "Please select at least one day")
        .required("Days of the week are required"),
      otherwise: Yup.array().nullable(),
    }),

  monthlyDay: Yup.number()
    .transform((value, originalValue) => {
      const parsed = Number(originalValue);
      return isNaN(parsed) ? undefined : parsed;
    })
    .typeError("Day of the month must be a number")
    .nullable()
    .when("recurrenceType", {
      is: "monthly",
      then: Yup.number()
        .required("Day of the month is required")
        .min(1, "Invalid day of the month")
        .max(31, "Invalid day of the month"),
      otherwise: Yup.number().nullable(),
    }),

  yearlyMonth: Yup.number()
    .transform((value, originalValue) => {
      const parsed = Number(originalValue);
      return isNaN(parsed) ? undefined : parsed;
    })
    .typeError("Month must be a number")
    .nullable()
    .when("recurrenceType", {
      is: "yearly",
      then: Yup.number()
        .required("Month is required")
        .min(1, "Invalid month")
        .max(12, "Invalid month"),
      otherwise: Yup.number().nullable(),
    }),

  yearlyDay: Yup.number()
    .transform((value, originalValue) => {
      const parsed = Number(originalValue);
      return isNaN(parsed) ? undefined : parsed;
    })
    .typeError("Day must be a number")
    .nullable()
    .when("recurrenceType", {
      is: "yearly",
      then: Yup.number()
        .required("Day is required")
        .min(1, "Invalid day")
        .max(31, "Invalid day"),
      otherwise: Yup.number().nullable(),
    }),
});

  useEffect(() => {
    loadSchedule();
  }, []);

  const loadSchedule = async () => {
    setIsLoading(true);
    const response = await getScheduleApi.request({ mac_address: route.params.item.id });
    console.log("Response from server: ", response.data);
    
    const storedTimezone = await keepConnectStore.get("kc_appTimezone");
    const correctedStoredTimezone = (storedTimezone !== null ? storedTimezone.replace(/\"/g, "") : "America/New_York");
    console.log("Stored Timezone: ", correctedStoredTimezone);
    setAppTimeZone(correctedStoredTimezone);
  
    if (response.ok && response.data) {
      const { next_event_time, recurrence_model } = response.data;
      if (next_event_time) {
        console.log("UTC time from server:", next_event_time);
        
        // Parse the server time as UTC
        const eventTimeInUTC = new Date(next_event_time + 'Z');  // Append 'Z' to treat as UTC
        console.log("Parsed UTC time:", eventTimeInUTC.toISOString());
  
        console.log("System timezone:", Intl.DateTimeFormat().resolvedOptions().timeZone);
  
        // Convert UTC to stored timezone
        const formatter = new Intl.DateTimeFormat('en-US', {
          timeZone: correctedStoredTimezone,
          year: 'numeric', month: '2-digit', day: '2-digit',
          hour: '2-digit', minute: '2-digit', second: '2-digit',
          hour12: false
        });
  
        const eventTimeInStoredTimezone = new Date(formatter.format(eventTimeInUTC));
        console.log("Time in stored timezone:", eventTimeInStoredTimezone.toLocaleString('en-US', {timeZone: correctedStoredTimezone}));
  
        setEventTime(eventTimeInStoredTimezone);
        setScheduleExists(true);
      } else {
        setScheduleExists(false);
      }
  
      if (recurrence_model) {
        const recurrence = recurrence_model;
        setIsRecurring(true);
        setRecurrenceType(recurrence.type);
        setRecurrenceInterval(recurrence.interval || 1);
        setDaysOfWeek(recurrence.days_of_week || []);
        setMonthlyDay(recurrence.monthly_day || null);
        setYearlyMonth(recurrence.yearly_month || null);
        setYearlyDay(recurrence.yearly_day || null);
      }
    } else {
      setScheduleExists(false);
    }
  
    setIsLoading(false);
  };

  // Updated handleSubmit to use form values instead of state variables
  const handleSubmit = async (values) => {
    setIsLoading(true);
    console.log("Submit button clicked with values:", values);
    const recurrenceModel = values.isRecurring
      ? {
          type: values.recurrenceType,
          interval: Number(values.recurrenceInterval),
          days_of_week: values.daysOfWeek,
          monthly_day: Number(values.monthlyDay),
          yearly_month: Number(values.yearlyMonth),
          yearly_day: Number(values.yearlyDay),
        }
      : null;

    const payload = {
      mac_address: route.params.item.id,
      next_event_time: eventTime.toISOString(),
      recurrence_model: recurrenceModel ? JSON.stringify(recurrenceModel) : null,
    };

    const response = await saveScheduleApi.request(payload);
    if (!response.ok) {
      console.error("Failed to save schedule");
    } else {
      Alert.alert("Success", "Schedule saved successfully");
      loadSchedule();
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const payload = {
      mac_address: route.params.item.id,
      delete: true,
    };
  
    const response = await saveScheduleApi.request(payload);
    if (!response.ok) {
      console.error("Failed to delete schedule");
    } else {
      setScheduleExists(false);
      Alert.alert("Success", "Schedule deleted successfully");
      loadSchedule();
    }
  };

  return (
    <Screen>
      {isLoading ? (
        <ActivityIndicator size="large" color={colors.primary} />
      ) : (
        <>
          {scheduleExists ? (
            <View style={styles.scheduleCard}>
              <Text style={styles.scheduleCardTitle}>Next Scheduled Event</Text>
              <Text style={styles.tzText}>{appTimezone}</Text>
              <Text style={styles.scheduleCardText}>
                {eventTime.toDateString()} at {eventTime.toLocaleTimeString()}
              </Text>
            </View>
          ) : (
            <View style={styles.scheduleCard}>
            <Text style={styles.noScheduleText}>No schedule set</Text>
            </View>
          )}

          <Form
            initialValues={{
              isRecurring: isRecurring, // Added isRecurring to initialValues
              recurrenceType: recurrenceType,
              recurrenceInterval: recurrenceInterval,
              daysOfWeek: daysOfWeek,
              monthlyDay: monthlyDay,
              yearlyMonth: yearlyMonth,
              yearlyDay: yearlyDay,
            }}
            onSubmit={handleSubmit}
            // validate={validate}
            validationSchema={validationSchema}
          >
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Schedule Event</Text>
              
              {/* Custom DateTimePicker */}
              <DateTimePickerWrapper
                label="Event Time"
                display="default"
                mode="datetime"
                value={eventTime}
                onChange={setEventTime}
              />

              <Switch
                labelText="Is this a recurring event?"
                name="isRecurring"
                formikOut={setIsRecurring}
              />
            </View>

            {isRecurring && (
              <>
                <View style={styles.section}>
                  <Text style={styles.sectionTitle}>Recurrence Settings</Text>
                  <Dropdown
                    labelText="Recurrence Type"
                    name="recurrenceType"
                    itemsArray={[
                      { label: "Daily", value: "daily" },
                      { label: "Weekly", value: "weekly" },
                      { label: "Monthly", value: "monthly" },
                      { label: "Yearly", value: "yearly" },
                    ]}
                    formikOut={setRecurrenceType}
                  />
                  
                  {/* Recurrence Interval Field */}
                  <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="default"
                    name="recurrenceInterval"
                    placeholder="1"
                    labelText={"Recurrence Interval"}
                    maxLength={250}
                    textAlignVertical="top"
                  />
                  {console.log("Days of the week:", daysOfWeek)}
                  {recurrenceType === "weekly" && (
                    <MultiSelect
                      labelText="Days of the Week"
                      multiple={true}
                      name="daysOfWeek"
                      itemsArray={[
                        { label: "Monday", value: "Monday" },
                        { label: "Tuesday", value: "Tuesday" },
                        { label: "Wednesday", value: "Wednesday" },
                        { label: "Thursday", value: "Thursday" },
                        { label: "Friday", value: "Friday" },
                        { label: "Saturday", value: "Saturday" },
                        { label: "Sunday", value: "Sunday" },
                      ]}
                      formikOut={setDaysOfWeek}
                    />
                  )}

                  {recurrenceType === "monthly" && (
                    <>
                      <LabelFormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType="default"
                        name="monthlyDay"
                        placeholder="1"
                        labelText={"Day of the Month"}
                        maxLength={250}
                        textAlignVertical="top"
                      />
                    </>
                  )}

                  {recurrenceType === "yearly" && (
                    <>
                      <LabelFormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType="default"
                        name="yearlyMonth"
                        placeholder="1"
                        labelText={"Month"}
                        maxLength={250}
                        textAlignVertical="top"
                      />
                      <LabelFormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardType="default"
                        name="yearlyDay"
                        placeholder="13"
                        labelText={"Day"}
                        maxLength={250}
                        textAlignVertical="top"
                      />
                    </>
                  )}
                </View>
              </>
            )}

            <SubmitButton title="Save Schedule" />
          </Form>

          {scheduleExists && (
            <Button title="Delete Schedule" onPress={handleDelete} />
          )}
        </>
      )}
    </Screen>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: "#f5f5f5",
  },
  section: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    marginBottom: 15,
    backgroundColor: "#fff",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
    elevation: 3,
  },
  sectionTitle: {
    fontWeight: "600",
    fontSize: 18,
    marginBottom: 10,
    color: "#333",
    textAlign: "center",
  },
  scheduleCard: {
    backgroundColor: "#e3f2fd",
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    shadowColor: "#000",
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
    elevation: 3,
  },
  scheduleCardTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
    color: "#1976d2",
  },
  scheduleCardText: {
    fontSize: 16,
    textAlign: "center",
    color: "#333",
  },
  noScheduleText: {
    fontStyle: "italic",
    fontSize: 16,
    textAlign: "center",
    color: "#888",
    marginVertical: 20,
  },
  tzText: {
    fontStyle: "italic",
    fontSize: 16,
    textAlign: "center",
    color: "#888",
  },
  label: {
    fontWeight: "bold",
    marginBottom: 5,
    color: "#555",
  },
  input: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 10,
    padding: 10,
    backgroundColor: "#f9f9f9",
    marginBottom: 15,
    fontSize: 16,
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  switchLabel: {
    fontWeight: "500",
    fontSize: 16,
    color: "#333",
  },
  button: {
    backgroundColor: colors.primary,
    padding: 15,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
    elevation: 5,
  },
  buttonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  deleteButton: {
    backgroundColor: "#ff6b6b",
    padding: 15,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    shadowColor: "#ff6b6b",
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
    elevation: 5,
  },
  deleteButtonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
});

export default ScheduleEventScreen;
