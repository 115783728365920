import React, { useState, useEffect } from "react";
import { StyleSheet, Image, View, Text, ActivityIndicator, Platform } from "react-native";
import CheckBox from '@react-native-community/checkbox';
//import Modal from 'react-native-modal';
import Modal from '../components/PlatformModal';
import * as Yup from "yup";
import { useMQTT } from '../components/MQTTContext';
//import * as FileSystem from 'expo-file-system';
//import * as Sharing from 'expo-sharing';
//import * as DocumentPicker from 'expo-document-picker';

import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
  LabelFormField,
  LabelFormFieldWithoutFormik,
} from "../components/forms";

import Screen from '../components/Screen';
import alert from '../components/alert';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import Switch from '../components/forms/Switch';
import routes from "../navigation/routes";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import colors from "../config/colors";
import useAuth from "../auth/useAuth";
//import { setStatusBarHidden } from "expo-status-bar"; //NOT USED


function ChangeSettingsScreen({ route, navigation }){
    const auth = useAuth();
    const getKeepConnectSettingsApi = useApi(keepConnectApi.getKeepConnectSettings);
    const changeSettingsKeepConnectApi = useApi(keepConnectApi.triggersKeepConnectApi);
    const { triggerHeartbeat } = useMQTT();
    const getKeepConnectPipelinesApi = useApi(keepConnectApi.getPipelines);
    const [data, setExistingSettings] = useState(); 
    const [newSettings1, setNewSettings1] = useState(false); //used for adding new settings with KeepConnectV2 
    const [newSettings2, setNewSettings2] = useState(false); //used for adding new settings with KeepConnectV3
    const [newSettings4, setNewSettings4] = useState(false); //used for adding new settings with KeepConnectV4
    const [newSettings5, setNewSettings5] = useState(false); //used for adding new settings with KeepConnectV5
    const [isLoading, setIsLoading] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false);
    const [requestMessage, setRequestMessage] = useState("");
    const [errorFromServer, setErrorFromServer] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [selectedNotifications, setSelectedNotifications] = useState("noNotif");
    const [selectedConnectionMode, setSelectedConnectionMode] = useState("0");
    const [masterFollower, setMasterFollower] = useState("enableMaster");
    const [commsMode, setCommsMode] = useState("0");
    const [enableAdvanced, setEnableAdvanced] = useState(false);
    const [hourOfDay, setHourOfDay] = useState(12);
    const [utc, setUTC] = useState(-5);
    const [disableDNSreset, setDisableDNSreset] = useState(false);
    const [enableStaticIP, setEnableStaticIP] = useState(false);
    const [enableStaticEth, setEnableStaticEth] = useState(false);
    const [reduceTxPower, setReduceTxPower] = useState(false);
    const [invertRelay, setInvertRelay] = useState(false);
    const [enableCustomSSID, setEnableCustomSSID] = useState(false);
    const [wifiResetMode, setSelectedWiFiResetMode] = useState("1");
    const [wifi802_11Mode, setSelected802_11Mode] = useState("3");
    const [enableWebPortal, setEnableWebPortal] = useState(true);
    const [enableDST, setEnableDST] = useState(true);
    const [connectionMonitorMode, setConnectionMonitorMode] = useState("Fullstack");
    const [keepConnectCode, setKeepConnectCode] = useState("");
    const [stagedSettings, setStagedSettings] = useState({});
    const [visibleTrigger, setVisibleTrigger] = useState(false);
    const [visibleTrigger2, setVisibleTrigger2] = useState(true);
    const [visibleTrigger3, setVisibleTrigger3] = useState(false);
    const [defaultPhoneNumber, setDefaultPhoneNumber] = useState("XXXXXXXXXX");
    const [defaultCountryCode, setDefaultCountryCode] = useState("1");
    const [defaultEmailAddress, setDefaultEmailAddress] = useState("");
    const [defaultTestSite1, setDefaultTestSite1] = useState("www.google.com");
    const [defaultTestSite2, setDefaultTestSite2] = useState("www.cnn.com");
    const [defaultPollTime, setDefaultPollTime] = useState("5");
    const [defaultLastChance, setDefaultLastChance] = useState("1");
    const [defaultOnTime, setDefaultOnTime] = useState("3");
    const [defaultResetTime, setDefaultResetTime] = useState("30");
    const [defaultPowerOnDelay, setDefaultPowerOnDelay] = useState("0");
    const [defaultMaxNumberOfContinuousResets, setDefaultMaxNumberOfContinuousResets] = useState("3");
    const [defaultSustainedOutageRetry, setDefaultSustainedOutageRetry] = useState("4");
    const [defaultAutoResetPeriod, setDefaultAutoResetPeriod] = useState("0");
    const [defaultSocketTimeout, setDefaultSocketTimeout] = useState("20");
    const [defaultLocalIP, setDefaultLocalIP] = useState("");
    const [defaultLocalSubnet, setDefaultLocalSubnet] = useState("");
    const [defaultLocalGateway, setDefaultLocalGateway] = useState("");
    const [defaultLocalDNS1, setDefaultLocalDNS1] = useState("");
    const [defaultLocalDNS2, setDefaultLocalDNS2] = useState("");
    const [localEthIP, setLocalEthIP] = useState("");
    const [localEthSubnet, setLocalEthSubnet] = useState("");
    const [localEthGateway, setLocalEthGateway] = useState("");
    const [localEthDNS1, setLocalEthDNS1] = useState("");
    const [localEthDNS2, setLocalEthDNS2] = useState("");
    const [defaultLocalSSID, setDefaultLocalSSID] = useState("");
    const [vpnEnabled, setVPNenabled] = useState(false);
    const [enterpriseOrPro, setEnterpriseOrPro] = useState(false);
    const [vpnPort, setVPNport] = useState("4550");
    const [vpnMode, setVPNmode] = useState("0");
    const [customPipelines, setCustomPipelines] = useState("");
    const [pipelinesNames, setPipelinesNames] = useState([]);
    const [includeSettings, setIncludeSettings] = useState(true);
    const [includePipelines, setIncludePipelines] = useState(true);
    const [saveForExport, setSaveForExport] = useState(false);
    const [GuestMode, setGuestMode] = useState(false);
    const [AdditionalEmails, setAdditionalEmails] = useState("");
    const [enableAdditionalEmails, setEnableAdditionalEmails] = useState(false);
    const [enableWebhook, setEnableWebhook] = useState(false);
    const [webhookURL, setWebhookURL] = useState("");
    const [webhookKey, setWebhookKey] = useState("text");
    const [enableMQTT, setEnableMQTT] = useState(false);
    const [mqttHost, setMqttHost] = useState('');
    const [mqttClient, setMqttClient] = useState('');
    const [mqttUser, setMqttUser] = useState('');
    const [mqttPassword, setMqttPassword] = useState('');
    const [mqttPort, setMqttPort] = useState(1883);
    const [messages, setMessages] = useState([]);
    const [enableEth, setEnableEth] = useState(false);

    const validationSchema = Yup.object().shape({
      email: Yup.string().email().label("Email"),
      phoneNumber: Yup.string().required().label("Phone Number"), 
      countryCode: Yup.string().required().max(3).label("Country Code"), 
      domain1: Yup.string().required().label("Primary Domain"), 
      domain2: Yup.string().required().label("Backup Domain"), 
      pollPeriod: Yup.string().required().max(8).label("Poll Period"), 
      lastChance: Yup.string().required().max(8).label("Backup Poll Delay"), 
      resetTime: Yup.string().required().max(8).label("Reset Time"), 
      onTime: Yup.string().required().max(8).label("On Time"), 
      powerOnDelay: Yup.string().required().max(8).label("Power On Delay"), 
      maxContinuousResets: Yup.number().required().min(1).max(99).label("Max Continuous Resets"), 
      sustainedOutageRetry: Yup.number().required().min(1).max(99).label("Sustained Outage Retry"), 
      autoResetInterval: Yup.number().required().min(0).max(999).label("Auto Reset Interval"), 
      socketTimeout: Yup.number().required().min(0).max(999).label("Socket Timeout"),
      ip: Yup.string().when('enableIP', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter IP Address")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid IP Address").label("Local IP Address"), //.when('eStaticIP', {is: true, then: Yup.string().required("Must enter IP Address")})
      sm: Yup.string().when('ip', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter Subnet Mask")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid Subnet Mask").label("Local Subnet Mask"), 
      gw: Yup.string().when('ip', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter Gateway IP")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid Gateway IP").label("Local Gateway"), 
      dns1: Yup.string().when('ip', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter DNS Server 1")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid DNS1 IP").label("Local DNS1"), 
      dns2: Yup.string().matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid DNS2 IP ").label("Local DNS2"), 
      ipEth: Yup.string().when('enableIPEth', {is: ()=> enableStaticEth, then: Yup.string().required("Must enter IP Address")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid IP Address").label("Local IP Address"), //.when('eStaticIP', {is: true, then: Yup.string().required("Must enter IP Address")})
      smEth: Yup.string().when('ipEth', {is: ()=> enableStaticEth, then: Yup.string().required("Must enter Subnet Mask")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid Subnet Mask").label("Local Subnet Mask"), 
      gwEth: Yup.string().when('ipEth', {is: ()=> enableStaticEth, then: Yup.string().required("Must enter Gateway IP")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid Gateway IP").label("Local Gateway"), 
      dns1Eth: Yup.string().when('ipEth', {is: ()=> enableStaticEth, then: Yup.string().required("Must enter DNS Server 1")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid DNS1 IP").label("Local DNS1"), 
      dns2Eth: Yup.string().matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid DNS2 IP ").label("Local DNS2"), 
      SSID: Yup.string().max(20).label("Custom WiFi SSID"),       
      vpnP: Yup.number().required().min(0).max(65535).label("VPN Port"),
    });
    


    useEffect(() => {
         loadKeepConnects();
     }, []);

    const loadKeepConnects = async () => {
      const keepConnectCodeTemp = await keepConnectStore.get("keepConnect");
      setKeepConnectCode(keepConnectCodeTemp.replace(/\"/g, "")); //found this function to remove the double quotes
      // const storedLicense = await keepConnectStore.get("license");
      // const correctedStoredLicense = (storedLicense !== null ? storedLicense.replace(/\"/g, "") : "basic");
      setEnterpriseOrPro(auth?.userPermissions?.license == "enterprise" || auth?.userPermissions?.license == "pro");
      }

    const toggleModal = () => {
      setVisibleTrigger(!visibleTrigger);
      };

    const cancelSend = ()=> {
      setStagedSettings({});
      toggleModal();
    }

    const navigateToPipelines = ()=> {
      if(enterpriseOrPro && newSettings4) navigation.navigate(routes.PIPELINES, { item: route.params.item, setCustomPipelines: setCustomPipelines, customPipelines: customPipelines, setPipelinesNames: setPipelinesNames, pipelinesNames: pipelinesNames});
      else{
        var msg = "";
        if (!enterpriseOrPro){
            msg = "Pro or Enterprise License Is Required.";
        } else if(!newSettings4){
            msg = "Device requires firmware update.";
        }
        alert(
            "Error",
            msg,
            [
              { text: "OK", onPress: () => {} }
            ]
          );
      }
    }

    const initiateSubmit = async ({ email, phoneNumber, countryCode, domain1, domain2, pollPeriod, lastChance, resetTime, onTime, powerOnDelay, maxContinuousResets, sustainedOutageRetry, autoResetInterval, socketTimeout, ssid, ip, sm, gw, dns1, dns2, ipEth, smEth, gwEth, dns1Eth, dns2Eth, vpnP, AdditionalEmails, mqttHost, mqttClient, mqttUser, mqttPassword, mqttPort, webhookURL, webhookKey}) => { //these args are destructures from when Formik sends an object to this function, therefore order doesn't matter.
      //convert messages array of objects to json
      let formattedMessages = {};
      messages.forEach((message, index) => {
        formattedMessages[`MSG${index + 1}`] = {
          MSG: message.MSG,
          count: message.count,
          delay: message.delay,
          interval: message.interval,
          dropout: message.dropout,
          action: message.action,
          thresh: message.thresh,
          direction: message.direction
        };
      });

      let formattedMessagesJson = JSON.stringify(formattedMessages);  
      
      let settingsTemp = { 
                notif: selectedNotifications, 
                eadd: email, 
                pn: phoneNumber, 
                cc: countryCode, 
                ts1: domain1, 
                ts2: domain2, 
                eflw: masterFollower,
                mfc: (newSettings2 ? commsMode : ""),
                pollt: pollPeriod, 
                ls: lastChance, 
                rt: resetTime, 
                ot: onTime, 
                pwrd: powerOnDelay, 
                mrst: maxContinuousResets, 
                sort: sustainedOutageRetry, 
                ari: autoResetInterval, 
                artod: hourOfDay, 
                tuo: utc, 
                edst: (enableDST ? "Enable Daylight Savings Time" : ""), 
                mnMd: connectionMonitorMode,
                enableDNSreset: (disableDNSreset ? "Ignore DNS Failures" : ""), 
                socketTimeout : socketTimeout,
                webPortal : (enableWebPortal ? 0:82), //82 is the disable code within device firmware
                SSID : (enableCustomSSID ? ssid : ""),
                enableStaticIP : (enableStaticIP ? 82:0),
                ip : ip,
                sm : sm,
                gw : gw,
                dns1 : dns1,
                dns2 : dns2,
                enableStaticEth : (enableStaticEth ? 82:0),
                ipEth : ipEth,
                smEth : smEth,
                gwEth : gwEth,
                dns1Eth : dns1Eth,
                dns2Eth : dns2Eth,
                tx : (reduceTxPower ? 1:0),
                inv : (invertRelay ? 1:0),
                wr : wifiResetMode,
                wm : wifi802_11Mode,
                vpnMode : vpnMode,
                vpnPort : vpnP,
                pipelines : customPipelines,
                pipelinesNames : pipelinesNames,
                keepConnects: keepConnectCode, 
                triggerType: "remoteSettingsChange",
                GuestMode: GuestMode,
                AdditionalEmails: AdditionalEmails,
                mqttEnable: enableMQTT,
                mqttHost: mqttHost,
                mqttClient: mqttClient,
                mqttUser: mqttUser,
                mqttPassword: mqttPassword,
                mqttPort: mqttPort,
                webhookURL: webhookURL,
                webhookKey: webhookKey,
                detections: formattedMessagesJson,
                connectionMode: selectedConnectionMode,
              };
        setStagedSettings(settingsTemp);
        if(saveForExport){
          saveSettingsToFile(settingsTemp, includeSettings, includePipelines);
          return;
        }
        toggleModal();
        };

        // Function to load settings from a file
        async function loadSettingsFromFile(includeSettings = false, includePipelines = false) {
          // Use the document picker to choose a file
          const file = await DocumentPicker.getDocumentAsync({});
        
          // If a file was selected
          if (file.type === 'success') {
            setIsLoading(true);
            let json;
            let settings;
            if (Platform.OS === 'web') {
              // Handle web platform
              const response = await fetch(file.uri);
              const blob = await response.blob();
        
              // Wrap the FileReader logic in a Promise
              const readFile = new Promise((resolve, reject) => {
                const reader = new FileReader();
        
                reader.onload = (event) => {
                  json = event.target.result;
                  settings = JSON.parse(json);
                  resolve(); // Resolve the Promise when done
                };
        
                reader.onerror = reject; // Reject the Promise on error
        
                reader.readAsText(blob);
              });
        
              // Wait for the Promise to resolve before continuing
              await readFile;
            } else {
              // Handle native platform
              json = await FileSystem.readAsStringAsync(file.uri);
              
              // Parse the JSON
              settings = JSON.parse(json);
              // ... rest of your code remains the same ...
            }
        
            // If includeSettings is true, set all your settings
            if (includeSettings) {
              if (settings.notif !== undefined) setSelectedNotifications(settings.notif);
              if (settings.eadd !== undefined) setDefaultEmailAddress(settings.eadd);
              if (settings.pn !== undefined) setDefaultPhoneNumber(settings.pn);
              if (settings.cc !== undefined) setDefaultCountryCode(settings.cc);
              if (settings.ts1 !== undefined) setDefaultTestSite1(settings.ts1);
              if (settings.ts2 !== undefined) setDefaultTestSite2(settings.ts2);
              if (settings.eflw !== undefined) setMasterFollower(settings.eflw);
              if (settings.mfc !== undefined) setCommsMode(settings.mfc);
              if (settings.pollt !== undefined) setDefaultPollTime(settings.pollt);
              if (settings.ls !== undefined) setDefaultLastChance(settings.ls);
              if (settings.rt !== undefined) setDefaultResetTime(settings.rt);
              if (settings.ot !== undefined) setDefaultOnTime(settings.ot);
              if (settings.pwrd !== undefined) setDefaultPowerOnDelay(settings.pwrd);
              if (settings.mrst !== undefined) setDefaultMaxNumberOfContinuousResets(settings.mrst);
              if (settings.sort !== undefined) setDefaultSustainedOutageRetry(settings.sort);
              if (settings.ari !== undefined) setDefaultAutoResetPeriod(settings.ari);
              if (settings.artod !== undefined) setHourOfDay(settings.artod);
              if (settings.tuo !== undefined) setUTC(settings.tuo);
              if (settings.edst !== undefined) setEnableDST(settings.edst === "Enable Daylight Savings Time");
              if (settings.mnMd !== undefined) setConnectionMonitorMode(settings.mnMd);
              if (settings.enableDNSreset !== undefined) setDisableDNSreset(settings.enableDNSreset === "Ignore DNS Failures");
              if (settings.socketTimeout !== undefined) setDefaultSocketTimeout(settings.socketTimeout);
              if (settings.webPortal !== undefined) setEnableWebPortal(settings.webPortal === 0);
              if (settings.SSID !== undefined){
                setEnableCustomSSID(settings.SSID !== "");
                if(settings.SSID !== "") setDefaultLocalSSID(settings.SSID);
              }
              if (settings.enableStaticIP !== undefined) setEnableStaticIP(settings.enableStaticIP === 82);
              if (settings.ip !== undefined) setDefaultLocalIP(settings.ip);
              if (settings.sm !== undefined) setDefaultLocalSubnet(settings.sm);
              if (settings.gw !== undefined) setDefaultLocalGateway(settings.gw);
              if (settings.dns1 !== undefined) setDefaultLocalDNS1(settings.dns1);
              if (settings.dns2 !== undefined) setDefaultLocalDNS2(settings.dns2);
              if (settings.enableStaticEth !== undefined) setEnableStaticEth(settings.enableStaticEth === 82);
              if (settings.ipEth !== undefined) setLocalEthIP(settings.ipEth);
              if (settings.smEth !== undefined) setLocalEthSubnet(settings.smEth);
              if (settings.gwEth !== undefined) setLocalEthGateway(settings.gwEth);
              if (settings.dns1Eth !== undefined) setLocalEthDNS1(settings.dns1Eth);
              if (settings.dns2Eth !== undefined) setLocalEthDNS2(settings.dns2Eth);
              if (settings.tx !== undefined) setReduceTxPower(settings.tx === 1);
              if (settings.inv !== undefined) setInvertRelay(settings.inv === 1);
              if (settings.wr !== undefined) setSelectedWiFiResetMode(settings.wr);
              if (settings.wm !== undefined) setSelected802_11Mode(settings.wm);
              if (settings.connectionMode !== undefined) setSelectedConnectionMode(settings.connectionMode);
              if (settings.vpnMode !== undefined) setVPNmode(settings.vpnMode);
              if (settings.vpnPort !== undefined) setVPNport(settings.vpnPort);
              //if (settings.keepConnects !== undefined) setKeepConnectCode(settings.keepConnects);
              if (settings.GuestMode !== undefined) setGuestMode(settings.GuestMode);
              if (settings.AdditionalEmails !== undefined && settings.AdditionalEmails != ""){
                setEnableAdditionalEmails(true);
                setAdditionalEmails(settings.AdditionalEmails);
              } else{
                setEnableAdditionalEmails(false);
                setAdditionalEmails("");
              }
              if (settings.powerCal !== undefined) setPowerCal(settings.powerCal);
              if (settings.enableMQTT !== undefined) setEnableMQTT(settings.enableMQTT);
              if (settings.mqttHost !== undefined) setMqttHost(settings.mqttHost);
              if (settings.mqttClient !== undefined) setMqttClient(settings.mqttClient);
              if (settings.mqttUser !== undefined) setMqttUser(settings.mqttUser);
              if (settings.mqttPassword !== undefined) setMqttPassword(settings.mqttPassword);
              if (settings.mqttPort !== undefined) setMqttPort(settings.mqttPort);
              if (settings.webhookURL !== undefined && settings.webhookURL !== null && settings.webhookURL != ""){
                setEnableWebhook(true);
                setWebhookURL(settings.webhookURL);
              } else{
                setEnableWebhook(false);
                setWebhookURL("");
              }
              if (settings.webhookKey !== undefined) setMqttPort(settings.webhookKey);
      
              // Load messages from the database response
              if (settings.detections !== undefined) {
                const fileMessages = JSON.parse(settings.messages);
                const loadedMessages = Object.keys(fileMessages).map((key) => {
                  const msg = fileMessages[key];
                  return {
                    id: parseInt(key.replace('MSG', ''), 10),
                    eMSG: false,
                    MSG: msg.MSG,
                    count: msg.count,
                    delay: msg.delay,
                    interval: msg.interval,
                    dropout: msg.dropout,
                    action: msg.action,
                    thresh: msg.thresh,
                    direction: msg.direction,
                    enableMoreSettings: false, // Adjust this based on your logic if needed
                    value: msg.value  // Default value for the new dropdown field
                  };
                });
                setMessages(loadedMessages);
              }
            }
        
            // If includePipelines is true, set pipelines and pipelineNames
            if (includePipelines) {
              if (settings.pipelines !== undefined) setCustomPipelines(settings.pipelines);
              if (settings.pipelinesNames !== undefined) setPipelinesNames(settings.pipelinesNames);
            }
          }
          setIsLoading(false);
          setVisibleTrigger3(false);
        }

        async function saveSettingsToFile(settingsTemp, includeSettings = false, includePipelines = false) {
          // Create a settings object
          let settings = {};
          
          // If includeSettings is true, add all your settings
          if (includeSettings) {
            // Add all your settings to the settings object
            settings = settingsTemp;
          }
        
          // If includePipelines is true, add pipelines and pipelineNames
          if (includePipelines && settings.mnMd == "P") {
            if (customPipelines == ""){
              try {
                const keepConnectCode = await keepConnectStore.get("keepConnect");
                const response = await getKeepConnectPipelinesApi.request(keepConnectCode);
                const bytesBase64 = response.data[0].pipelines;
                const commaSeparatedPipelinesNames = response.data[0].pipelinesNames;
                if(bytesBase64 != ""){
                  setCustomPipelines(bytesBase64);
                  setPipelinesNames(commaSeparatedPipelinesNames);
                  settings.pipelines = bytesBase64;
                  settings.pipelinesNames = commaSeparatedPipelinesNames;
                }
              } catch (e) {
                // If an error occurs (for example, the byte array is not available), create a default Pipeline
                //setPipelines([newPipeline]);
                //setIsLoading(false);
              }
            } else{
              settings.pipelines = customPipelines;
              settings.pipelinesNames = pipelinesNames;
            }
          }
        
          // Create a JSON string from the settings object
          const json = JSON.stringify(settings);
        
          if (Platform.OS === 'web') {
            // Handle web platform
            const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
        
            a.href = url;
            a.download = 'settings.json';
            a.click();
        
            URL.revokeObjectURL(url);
            setVisibleTrigger3(false);
          } else {
            // Handle native platform
            const fileUri = FileSystem.documentDirectory + 'settings.json';
            await FileSystem.writeAsStringAsync(fileUri, json);
        
            if (!(await Sharing.isAvailableAsync())) {
              alert(`Sharing is not available on your platform`);
              return;
            }
            await Sharing.shareAsync(fileUri);
            setVisibleTrigger3(false);
          }
        }

    const loadExistingSettings = async (useDefaults) => {
      //setIsloading(true);
      //setIsLoading(false);
      const keepConnectCode = await keepConnectStore.get("keepConnect");
      const response = await getKeepConnectSettingsApi.request(keepConnectCode);
      //setExistingSettings(response.data);
      //console.log(response);
      //console.log(response.data[0]);
      setVPNenabled(response.data[0].firmware.includes("VPN"));
      setEnableEth(response.data[0].firmware.includes("ETH"));

      const firmwareString = response.data[0].firmware;
      const majorVersionStart = firmwareString.indexOf("_V") + 2;
      const majorVersionEnd = firmwareString.indexOf("_", majorVersionStart);
      const majorVersion = parseInt(firmwareString.substring(majorVersionStart, majorVersionEnd), 10);

      setNewSettings5(majorVersion >= 2);
      setNewSettings4(majorVersion >= 4);
      setNewSettings2(majorVersion >= 3);
      setNewSettings1(majorVersion >= 2);

      //setNewSettings1(true);
      if(!useDefaults){

        //Do all the mapping here!
        //data[0].notificationType
        //console.log(response.data[0]);

        setSelectedNotifications(response.data[0].notificationType == "None" ? "noNotif" : response.data[0].notificationType);
        var StringPhoneNumber = String(response.data[0].phoneNumber);
        var PhoneNumberLength = StringPhoneNumber.length;
        if(PhoneNumberLength > 10){
          setDefaultCountryCode(StringPhoneNumber.substring(0, (PhoneNumberLength - 10)));
          setDefaultPhoneNumber(StringPhoneNumber.substring((PhoneNumberLength - 10), PhoneNumberLength));
        }
        setDefaultEmailAddress(response.data[0].emailAddress);
        setDefaultTestSite1(response.data[0].testSite1);
        setDefaultTestSite2(response.data[0].testSite2);
        setDefaultPollTime(String(Number(response.data[0].pollTime)/60000));
        setDefaultLastChance(String(Number(response.data[0].lastChance)/60000));
        setDefaultOnTime(String(Number(response.data[0].onTime)/60000));
        setDefaultResetTime(String(Number(response.data[0].resetTime)/1000));
        setDefaultPowerOnDelay(String(Number(response.data[0].powerOnDelay)/1000));
        setDefaultMaxNumberOfContinuousResets(response.data[0].maxNumberOfContinuousResets);
        setDefaultSustainedOutageRetry(String(Number(response.data[0].sustainedOutageRetry)/3600000));
        setUTC(Number(response.data[0].UTCOffset));
        setDefaultAutoResetPeriod(response.data[0].autoResetPeriod);
        setHourOfDay(Number(response.data[0].autoResetHourOfDay));
        setEnableDST(response.data[0].enableDST == "true" ? true : false);
        setMasterFollower(response.data[0].enableFollower == "false" ? "enableMaster" : "enableFollower");
        setCommsMode(response.data[0].masterFollowerMode);
        setDefaultSocketTimeout(response.data[0].socketTimeout ? response.data[0].socketTimeout : "20");
        setDisableDNSreset(response.data[0].enableDNSreset == "true" ? false : true);
        if(response.data[0].enableStaticIP == "true"){
          setEnableStaticIP(true);
          setDefaultLocalIP(response.data[0].localIP);
          setDefaultLocalSubnet(response.data[0].localSubnet);
          setDefaultLocalGateway(response.data[0].localGateway);
          setDefaultLocalDNS1(response.data[0].localDNS1);
          setDefaultLocalDNS2(response.data[0].localDNS2);
        } else{
          setEnableStaticIP(false);
          setDefaultLocalIP("");
          setDefaultLocalSubnet("");
          setDefaultLocalGateway("");
          setDefaultLocalDNS1("");
          setDefaultLocalDNS2("");
        }
        if(response.data[0].enableStaticEth == "true"){
          setEnableStaticEth(true);
          setLocalEthIP(response.data[0].EthIP);
          setLocalEthSubnet(response.data[0].EthSM);
          setLocalEthGateway(response.data[0].EthGW);
          setLocalEthDNS1(response.data[0].EthDNS1);
          setLocalEthDNS2(response.data[0].EthDNS2);
        } else{
          setEnableStaticEth(false);
          setLocalEthIP("");
          setLocalEthSubnet("");
          setLocalEthGateway("");
          setLocalEthDNS1("");
          setLocalEthDNS2("");
        }
        setSelectedWiFiResetMode(response.data[0].lossOfWiFiMode);
        setSelected802_11Mode(response.data[0].wifiMode)
        setVPNmode(response.data[0].vpnMode != null ? response.data[0].vpnMode : "0");
        setVPNport(response.data[0].vpnPort != null ? response.data[0].vpnPort : "4550");
        setReduceTxPower(Number(response.data[0].reduceTransmit) == 1 ? true: false);
        setInvertRelay(Number(response.data[0].inv) == 1 ? true: false);
        setSelectedConnectionMode(response.data[0].connectionMode);
        let StringSSID = String(response.data[0].customSSID);
        let StringMAC = String(response.data[0].macAddress);

        let checkIfDefaultSSID = (response.data[0].firmware.includes("PowerPal") ? "PowerPal-" : "KeepConnect-") + (StringMAC.substring(StringMAC.length - 5, StringMAC.length)).replace(":", "");
        //console.log(checkIfDefaultSSID);
        if(StringSSID != checkIfDefaultSSID){
        setEnableCustomSSID(true);
        setDefaultLocalSSID(response.data[0].customSSID);
        } else{
          setEnableCustomSSID(false);
          setDefaultLocalSSID("");
        }
        setEnableWebPortal(response.data[0].webPortal == "true" ? true : false);
        if (response.data[0].enableKCRoundtrip == "true") {
          setConnectionMonitorMode("KCRoundtrip");
        } else if(response.data[0].customPipelines == 1){
          setConnectionMonitorMode("P");
        } else if(response.data[0].DisableMonitoring == 1){
          setConnectionMonitorMode("O");
        } else {
            setConnectionMonitorMode(response.data[0].enablePingOnly == "true" ? "Only Monitor Using Ping" : "Fullstack");
        }
        setGuestMode(response.data[0].GuestMode);
        if (response.data[0].AdditionalEmails != "" && response.data[0].AdditionalEmails != null){
          setEnableAdditionalEmails(true);
          setAdditionalEmails(response.data[0].AdditionalEmails);
        } else{
          setEnableAdditionalEmails(false);
          setAdditionalEmails("");
        }
        if (response.data[0].enableMQTT !== undefined) setEnableMQTT(Number(response.data[0].enableMQTT) == 1);
        if (response.data[0].mqttHost !== undefined) setMqttHost(response.data[0].mqttHost);
        if (response.data[0].mqttClient !== undefined) setMqttClient(response.data[0].mqttClient);
        if (response.data[0].mqttUser !== undefined) setMqttUser(response.data[0].mqttUser);
        if (response.data[0].mqttPassword !== undefined) setMqttPassword(response.data[0].mqttPassword);
        if (response.data[0].mqttPort !== undefined) setMqttPort(response.data[0].mqttPort);
        if (response.data[0].webhookURL !== undefined && response.data[0].webhookURL !== null && response.data[0].webhookURL !== ""){
          setEnableWebhook(true);
          setWebhookURL(response.data[0].webhookURL);
        } else{
          setEnableWebhook(false);
          setWebhookURL("");
        }
        if (response.data[0].webhookKey !== undefined) setWebhookKey(response.data[0].webhookKey);

        // Load messages from the database response
        if (response.data[0].detections) {
          const dbMessages = JSON.parse(response.data[0].detections);
          const loadedMessages = Object.keys(dbMessages).map((key) => {
            const msg = dbMessages[key];
            return {
              id: parseInt(key.replace('MSG', ''), 10),
              eMSG: false,
              MSG: msg.MSG,
              count: msg.count,
              delay: msg.delay,
              interval: msg.interval,
              dropout: msg.dropout,
              action: msg.action,
              thresh: msg.thresh,
              direction: msg.direction,
              enableMoreSettings: false, // Adjust this based on your logic if needed
              value: msg.value  // Default value for the new dropdown field
            };
          });
          setMessages(loadedMessages);
        }
      }
      setIsLoading(false);
      }

    const initializeSettings = async (useDefaults) => {
      setVisibleTrigger2(!visibleTrigger2);
      setIsLoading(true);
      loadExistingSettings(useDefaults);
      
    };

    const handleSubmit = async () => {
        toggleModal();
        //console.log(stagedSettings);
        //return;
        const response = await changeSettingsKeepConnectApi.request(stagedSettings);
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        alert(
          "Success!",
          "Settings were staged successfully. Your device will download and apply these in appx 5min.",
          [
            { text: "OK", onPress: () => {} }
          ]
        );
        triggerHeartbeat(keepConnectCode);
        route.params.changedDevices(true); //for refreshing upstream screen
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        };

        useEffect(() => {
          if (customPipelines && customPipelines.length > 0 && connectionMonitorMode != "P") {
            setConnectionMonitorMode("P");
          }
        }, [customPipelines]);

        useEffect(() => {
          if (connectionMonitorMode !== "P") {
            setCustomPipelines("");
            setPipelinesNames("");
          }
        }, [connectionMonitorMode]);

        //console.log("Pipelines: ");
        //console.log(customPipelines);

        const addMessage = () => {
          const newMessage = {
            id: messages.length + 1, // sequential id starting from 1
            eMSG: true,
            MSG: 'Power Pal: High Power Detected',
            thresh: '1000',
            delay: '0',
            count: '1',
            interval: '0',
            dropout: '60',
            enableMoreSettings: false,
            direction: '1',
            action: 'none',
            value: 'Power'  // Default value for the new dropdown field
          };
          setMessages([...messages, newMessage]);
        };
      
        const removeMessage = (id) => {
          const updatedMessages = messages.filter(message => message.id !== id);
          setMessages(updatedMessages);
        };
      
        const handleSwitchChange = (id, field, value) => {
          const updatedMessages = messages.map(message =>
            message.id === id ? { ...message, [field]: value } : message
          );
          setMessages(updatedMessages);
        };
      
        const valueOptions = [
          { label: 'Power', value: 'Power' },
          { label: 'Voltage', value: 'Voltage' },
          { label: 'Current', value: 'Current' },
        ];



    return(
    <Screen>
        {isLoading ? <ActivityIndicator size="large" color={colors.primary}/> :
        <React.Fragment>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Enter New Settings for Keep Connect:</Text>
        <Text style={{alignSelf: "center"}}>{route.params.item.Name}</Text>
        <View style={{padding: 20, borderBottomWidth :3, borderBottomColor: colors.primary}}>
        <Form
        initialValues={{ email: defaultEmailAddress, phoneNumber: defaultPhoneNumber, countryCode: defaultCountryCode,  domain1: defaultTestSite1, domain2: defaultTestSite2, pollPeriod: defaultPollTime, lastChance: defaultLastChance, resetTime: defaultResetTime, onTime: defaultOnTime, powerOnDelay: defaultPowerOnDelay, maxContinuousResets: defaultMaxNumberOfContinuousResets, sustainedOutageRetry: defaultSustainedOutageRetry, autoResetInterval: defaultAutoResetPeriod, socketTimeout: defaultSocketTimeout, ip: defaultLocalIP, sm: defaultLocalSubnet, gw: defaultLocalGateway, dns1: defaultLocalDNS1, dns2: defaultLocalDNS2, ipEth: localEthIP, smEth: localEthSubnet, gwEth: localEthGateway, dns1Eth: localEthDNS1, dns2Eth: localEthDNS2, ssid: defaultLocalSSID, vpnP: vpnPort, GuestMode, AdditionalEmails, mqttHost, mqttClient, mqttUser, mqttPassword, mqttPort, webhookURL, webhookKey }} //order doesn't matter since these are key:values
        onSubmit={initiateSubmit}
        validationSchema={validationSchema}
        >
        {enableEth && newSettings5 && <Dropdown
          labelText='Select Connection Mode'
          defaultValueProp={selectedConnectionMode}
          itemsArray= {[
            {label: '2.4 GHz WiFi', value: '0'},
            {label: 'Ethernet (Wired)', value: '1' },
            {label: 'WiFi and Ethernet', value: '2' },
            ]}
          onChangeItemProp={setSelectedConnectionMode}
        />}
        <Dropdown
          labelText='Select Notification Type'
          defaultValueProp={selectedNotifications}
          itemsArray= {[
            {label: 'No Notifications', value: 'noNotif'},
            {label: 'Email Notifications', value: 'Email' },
            {label: 'SMS Notifications', value: 'SMS' },
            {label: 'Email and SMS', value: 'BOTH' },
            ]}
          onChangeItemProp={setSelectedNotifications}
        />
        {(selectedNotifications==='Email' || selectedNotifications == 'BOTH') && <><FormField
          autoCapitalize="none"
          autoCorrect={false}
          icon="email"
          keyboardType="email-address"
          name="email"
          placeholder="Email"
          textContentType="emailAddress"
        />
        
        {<Switch
            labelText='Enable Additional Email Addresses'
            switchState={enableAdditionalEmails}
            setSwitchState={setEnableAdditionalEmails} 
            isDisabled={false}
          />}
          {enableAdditionalEmails && <LabelFormField
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="default"
            name="AdditionalEmails"
            placeholder=""
            labelText={"Additional Email Addresses\n(Comma Separated)"}
            multiline={true}
            maxLength={198}
            textAlignVertical="top"
          />}</>
        }
        
        {(selectedNotifications==='SMS' || selectedNotifications == 'BOTH') && <LabelFormField
          autoCapitalize="none"
          autoCorrect={false}
          icon="cellphone"
          keyboardType="numeric"
          name="phoneNumber"
          placeholder="XXXXXXXXXX"
          labelText='Enter Phone Number (10 Digits)'
        />}
        {(selectedNotifications==='SMS' || selectedNotifications == 'BOTH') && <LabelFormField
          autoCapitalize="none"
          autoCorrect={false}
          icon="plus"
          keyboardType="numeric"
          name="countryCode"
          placeholder="1"
          labelText = "Enter Country Code"
        />}

          {<Switch
            labelText='Enable Webhook'
            switchState={enableWebhook}
            setSwitchState={setEnableWebhook} 
            isDisabled={false}
          />}
          {enableWebhook && <LabelFormField
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="default"
            name="webhookURL"
            placeholder=""
            labelText={"Enter Webhook URL"}
            maxLength={250}
            textAlignVertical="top"
          />}
          {enableWebhook && <LabelFormField
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="default"
            name="webhookKey"
            placeholder=""
            labelText={"Enter Webhook Key\n(Optional) Default=text"}
            maxLength={50}
            textAlignVertical="top"
          />}
        <Switch 
          labelText='Show Advanced Settings'
          switchState={enableAdvanced}
          setSwitchState={setEnableAdvanced} 
          isDisabled = {false}
        />
        {enableAdvanced && <View style={{paddingTop: 20, borderTopWidth :3, borderTopColor: colors.primary}}></View>}
        {enableAdvanced && <Text style={{fontWeight: "bold", alignSelf: "center"}}>Operating Mode:</Text>}
        {enableAdvanced && <Dropdown
          labelText='Select Keep Connect Operating Mode'
          defaultValueProp={masterFollower}
          itemsArray= {[
            {label: 'Master (Default)', value: 'enableMaster'},
            {label: 'Follower (Requires a Master)', value: 'enableFollower' },
            ]}
          onChangeItemProp={setMasterFollower}
          onTop={true}
        />}<Text></Text>
        {enableAdvanced && newSettings2 && <><Dropdown
          labelText='Master/Follower Comms Mode'
          defaultValueProp={commsMode}
          itemsArray= {[
            {label: 'Hybrid (KeepLink and LAN)', value: '0'},
            {label: 'KeepLink (Peer to Peer)', value: '1' },
            {label: 'LAN (Uses WiFi Network)', value: '2' },
            ]}
          onChangeItemProp={setCommsMode}
        /><Text></Text></>}
        {enableAdvanced && masterFollower==="enableMaster" && <View style={{paddingTop: 20, borderTopWidth :3, borderTopColor: colors.primary}}></View>}
        {enableAdvanced && masterFollower==="enableMaster" && <Text style={{fontWeight: "bold", alignSelf: "center"}}>Monitoring Mode:</Text>}
        {enableAdvanced && masterFollower==="enableMaster" && <Dropdown
          labelText='Connection Monitor Mode:'
          defaultValueProp={connectionMonitorMode}
          itemsArray= {(() => { //there are useEffect hooks on both customPipelines and connectionMonitorMode that drive this
            let options = newSettings1 ? [
              {label: 'Require Full TCP/HTTPS Success', value: "Fullstack"},
              {label: 'Only Monitor Using Ping', value: "Only Monitor Using Ping" },
              {label: 'Keep Connect Roundtrip', value: "KCRoundtrip" },
            ] : [
              {label: 'Require Full TCP/HTTPS Success', value: "Fullstack"},
              {label: 'Only Monitor Using Ping', value: "Only Monitor Using Ping" },
            ];

            if (newSettings5) {
              options.push({ label: 'Disable Monitoring', value: "O" });
            }
        
            // Check if customPipelines has data in it
            if (connectionMonitorMode == "P" || (customPipelines && customPipelines.length > 0)) {
              // Add "Custom Pipeline" option
              options.push({label: 'Custom Pipelines', value: "P"});
            }
        
            return options;
          })()}
          onChangeItemProp={setConnectionMonitorMode}
        />}
        {enableAdvanced && <Button
            title="Setup Custom Pipeline"
            onPress={() => navigateToPipelines()}
        />}
        {enableAdvanced && masterFollower==="enableMaster" && newSettings1 && connectionMonitorMode !== "P" && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="socketTimeout"
                    placeholder="20"
                    labelText = {"Socket Timeout (Seconds)"}
                  />}
        {enableAdvanced && masterFollower==="enableMaster" && connectionMonitorMode != "KCRoundtrip" && connectionMonitorMode !== "P" && <LabelFormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="default"
                  name="domain1"
                  placeholder="www.google.com"
                  labelText = "Primary Test Domain"
                />}
        {enableAdvanced && masterFollower==="enableMaster" && connectionMonitorMode != "KCRoundtrip" && connectionMonitorMode !== "P" && <LabelFormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="default"
                  name="domain2"
                  placeholder="www.cnn.com"
                  labelText = "Backup Test Domain"
                />}
        {enableAdvanced && masterFollower==="enableMaster" && connectionMonitorMode !== "P" && newSettings1 && <Dropdown
          labelText='Loss of WiFi Reset Mode'
          defaultValueProp={wifiResetMode}
          itemsArray= {[
            {label: 'Regular Resets', value: '0'},
            {label: 'Reboot/Retry Before Resetting', value: '1' },
            {label: 'Block Resets, Await Reconnection', value: '2' },
            ]}
          onChangeItemProp={setSelectedWiFiResetMode}
        />}
        {enableAdvanced && masterFollower==="enableMaster" && connectionMonitorMode !== "P" && <Switch 
          labelText='Ignore DNS Failures if Ping 8.8.8.8 Works'
          switchState={disableDNSreset}
          setSwitchState={setDisableDNSreset} 
          isDisabled = {false}
        />}
        {enableAdvanced && <View style={{paddingTop: 20, borderTopWidth :3, borderTopColor: colors.primary}}></View>}
        {enableAdvanced && <Text style={{fontWeight: "bold", alignSelf: "center"}}>Timing Settings:</Text>}
        {enableAdvanced && masterFollower==="enableMaster" && connectionMonitorMode !== "P" && <LabelFormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="numeric"
                  name="pollPeriod"
                  placeholder="5"
                  labelText = {"How Often to Check Connection\n(Minutes)"}
                />}
        {enableAdvanced && masterFollower==="enableMaster" && connectionMonitorMode !== "P" && <LabelFormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="numeric"
                  name="lastChance"
                  placeholder="1"
                  labelText = {"Pause Before Backup Check If Primary Fails\n(Minutes)"}
                />}
        {enableAdvanced && connectionMonitorMode !== "P" && <LabelFormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="numeric"
                  name="resetTime"
                  placeholder="30"
                  labelText = {"How Long to Kill Power to Devices\n(Seconds)"}
                />}
        {enableAdvanced && <LabelFormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="numeric"
                  name="onTime"
                  placeholder="3"
                  labelText = {"How Long to Wait After Reset Before Reconnecting\n(Minutes)"}
                />}
        {enableAdvanced && <LabelFormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="numeric"
                  name="powerOnDelay"
                  placeholder="0"
                  labelText = "Power-On Delay (Seconds)"
                />}
        {enableAdvanced && masterFollower==="enableMaster" && connectionMonitorMode !== "P" && <LabelFormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="numeric"
                  name="maxContinuousResets"
                  placeholder="3"
                  labelText = {"Enter Max Number of Continuous Resets\n(1-99)"}
                />}
        {enableAdvanced && masterFollower==="enableMaster" && connectionMonitorMode !== "P" && <LabelFormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="numeric"
                  name="sustainedOutageRetry"
                  placeholder="4"
                  labelText = {"Sustained Outage Retry\n(Hours)"}
                />}
        <Text></Text>
        {enableAdvanced && masterFollower==="enableMaster" && <View style={{paddingTop: 20, borderTopWidth :3, borderTopColor: colors.primary}}></View>}
        {enableAdvanced && masterFollower==="enableMaster" && <Text style={{fontWeight: "bold", alignSelf: "center"}}>Configure Auto Reset:</Text>}
        {enableAdvanced && masterFollower==="enableMaster" && <LabelFormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="numeric"
                  name="autoResetInterval"
                  placeholder="0"
                  labelText = {"Enter Auto Reset Interval in Days\n(Enter 0 to Disable)"}
                />}
        {enableAdvanced && masterFollower==="enableMaster" && <Dropdown
          onTop={true}
          labelText='Enter Hour of Day for Auto Reset to Occur'
          defaultValueProp={hourOfDay}
          itemsArray= {[
            { label: "12AM" ,value: 0 },{ label: "1AM" ,value: 1 },{ label: "2AM" ,value: 2 },{ label: "3AM" ,value: 3 },{ label: "4AM" ,value: 4 },{ label: "5AM" ,value: 5 },{ label: "6AM" ,value: 6 },{ label: "7AM" ,value: 7 },{ label: "8AM" ,value: 8 },{ label: "9AM" ,value: 9 },{ label: "10AM" ,value: 10 },{ label: "11AM" ,value: 11 },{ label: "12PM" ,value: 12 },{ label: "1PM" ,value: 13 },{ label: "2PM" ,value: 14 },{ label: "3PM" ,value: 15 },{ label: "4PM" ,value: 16 },{ label: "5PM" ,value: 17 },{ label: "6PM" ,value: 18 },{ label: "7PM" ,value: 19 },{ label: "8PM" ,value: 20 },{ label: "9PM" ,value: 21 },{ label: "10PM" ,value: 22 },{ label: "11PM" ,value: 23 },
            ]}
          onChangeItemProp={setHourOfDay}
        />}
        {enableAdvanced && masterFollower==="enableMaster" && <Dropdown
          labelText={"Enter Timezone UTC Offset\n(+/- UTC)"}
          defaultValueProp={utc}
          itemsArray= {[
            { label: "-12:00" ,value: -12 },{ label: "-11:00" ,value: -11 },{ label: "-10:00" ,value: -10 },{ label: "-9:00" ,value: -9 },{ label: "-8:00 US Pacific" ,value: -8 },{ label: "-7:00 US Mountain" ,value: -7 },{ label: "-6:00 US Central" ,value: -6 },{ label: "-5:00 US Eastern" ,value: -5 },{ label: "-4:00" ,value: -4 },{ label: "-3:00" ,value: -3 },{ label: "-2:00" ,value: -2 },{ label: "-1:00" ,value: -1 },{ label: "0:00" ,value: 0 },{ label: "+1:00" ,value: 1 },{ label: "+2:00" ,value: 2 },{ label: "+3:00" ,value: 3 },{ label: "+4:00" ,value: 4 },{ label: "+5:00" ,value: 5 },{ label: "+6:00" ,value: 6 },{ label: "+7:00" ,value: 7 },{ label: "+8:00" ,value: 8 },{ label: "+9:00" ,value: 9 },{ label: "+10:00" ,value: 10 },{ label: "+11:00" ,value: 11 },{ label: "+12:00" ,value: 12 },{ label: "+13:00" ,value: 13 },{ label: "+14:00" ,value: 14 },
            ]}
          onChangeItemProp={setUTC}
        />}
        {enableAdvanced && masterFollower==="enableMaster" && <Switch 
          labelText='Enable Daylight Savings Time'
          switchState={enableDST}
          setSwitchState={setEnableDST} 
          isDisabled = {false}
        />}

        {enableAdvanced && newSettings5 && <View style={{paddingTop: 20, borderTopWidth :3, borderTopColor: colors.primary}}></View>}
        {enableAdvanced && newSettings5 && <Text style={{fontWeight: "bold", alignSelf: "center"}}>Additional Detections and Notifications:</Text>}
        {enableAdvanced && newSettings5 && (
                          <>
                          <Button title="Add New Detection" onPress={addMessage} />
                          {messages.map((message, index) => (
                            <View key={message.id} style={styles.deviceBox}>
                              <Switch
                                labelText={`Show Detection ${index + 1}`}
                                switchState={message.eMSG}
                                setSwitchState={(value) => handleSwitchChange(message.id, 'eMSG', value)}
                                isDisabled={false}
                              />
                              {message.eMSG && (
                                <>
                                  <Dropdown
                                    labelText="Detection Type"
                                    defaultValueProp={message.value}
                                    itemsArray={valueOptions}
                                    onChangeItemProp={(value) => handleSwitchChange(message.id, 'value', value)}
                                  />
                                  <LabelFormFieldWithoutFormik
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    keyboardType="numeric"
                                    name={`thresh${index + 1}`}
                                    placeholder="Threshold"
                                    labelText="Threshold"
                                    value={message.thresh}
                                    onChangeText={(value) => handleSwitchChange(message.id, 'thresh', value)}
                                  />
                                  <Dropdown
                                    labelText="Direction"
                                    defaultValueProp={message.direction}
                                    itemsArray={[
                                      { label: 'Greater Than', value: '1' },
                                      { label: 'Less Than', value: '0' }
                                    ]}
                                    onChangeItemProp={(value) => handleSwitchChange(message.id, 'direction', value)}
                                  />
                                  <Dropdown
                                    labelText="Action"
                                    defaultValueProp={message.action}
                                    itemsArray={[
                                      { label: 'None', value: 'none' },
                                      { label: 'Trip', value: 'trip' },
                                      { label: 'Power Cycle', value: 'power-cycle' }
                                    ]}
                                    onChangeItemProp={(value) => handleSwitchChange(message.id, 'action', value)}
                                  />
                                  <LabelFormFieldWithoutFormik
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    keyboardType="default"
                                    name={`MSG${index + 1}`}
                                    placeholder={`Message ${index + 1}`}
                                    labelText={`Message ${index + 1}`}
                                    multiline={true}
                                    maxLength={98}
                                    textAlignVertical="top"
                                    value={message.MSG}
                                    onChangeText={(value) => handleSwitchChange(message.id, 'MSG', value)}
                                  />
                                  <Switch
                                    labelText="More Message Settings"
                                    switchState={message.enableMoreSettings}
                                    setSwitchState={(value) => handleSwitchChange(message.id, 'enableMoreSettings', value)}
                                    isDisabled={false}
                                  />
                                  {message.enableMoreSettings && (
                                    <>
                                      <LabelFormFieldWithoutFormik
                                        autoCapitalize="none"
                                        autoCorrect={false}
                                        keyboardType="numeric"
                                        name={`delay${index + 1}`}
                                        placeholder="Delay"
                                        labelText="Notification Delay (Seconds)"
                                        value={message.delay}
                                        onChangeText={(value) => handleSwitchChange(message.id, 'delay', value)}
                                      />
                                      <LabelFormFieldWithoutFormik
                                        autoCapitalize="none"
                                        autoCorrect={false}
                                        keyboardType="numeric"
                                        name={`count${index + 1}`}
                                        placeholder="Count"
                                        labelText="Message Resend Count"
                                        value={message.count}
                                        onChangeText={(value) => handleSwitchChange(message.id, 'count', value)}
                                      />
                                      <LabelFormFieldWithoutFormik
                                        autoCapitalize="none"
                                        autoCorrect={false}
                                        keyboardType="numeric"
                                        name={`interval${index + 1}`}
                                        placeholder="Interval"
                                        labelText="Message Resend Interval (Minutes)"
                                        value={message.interval}
                                        onChangeText={(value) => handleSwitchChange(message.id, 'interval', value)}
                                      />
                                      <LabelFormFieldWithoutFormik
                                        autoCapitalize="none"
                                        autoCorrect={false}
                                        keyboardType="numeric"
                                        name={`dropout${index + 1}`}
                                        placeholder="Dropout"
                                        labelText="Message Re-arm Dropout (Minutes)"
                                        value={message.dropout}
                                        onChangeText={(value) => handleSwitchChange(message.id, 'dropout', value)}
                                      />
                                    </>
                                  )}
                                  <Button title="Remove Detection" onPress={() => removeMessage(message.id)} />
                                </>
                              )}
                            </View>
                          ))}
                          </>
                        )}
        {enableAdvanced && newSettings1 && <View style={{paddingTop: 20, borderTopWidth :3, borderTopColor: colors.primary}}></View>}
        {enableAdvanced && newSettings1 && <Text style={{fontWeight: "bold", alignSelf: "center"}}>Miscellaneous Settings:</Text>}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Enable Static IP'
          switchState={enableStaticIP}
          setSwitchState={setEnableStaticIP} 
          isDisabled = {false}
        />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="ip"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"IP Address"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="sm"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"Subnet Mask"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="gw"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"Gateway"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="dns1"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"DNS Server 1"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="dns2"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"DNS Server 2"}
                  />}
        {enableAdvanced && enableEth && <Switch 
          labelText='Enable Ethernet Static IP'
          switchState={enableStaticEth}
          setSwitchState={setEnableStaticEth} 
          isDisabled = {false}
        />}
        {enableAdvanced && enableStaticEth  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="ipEth"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"IP Address"}
                  />}
        {enableAdvanced && enableStaticEth  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="smEth"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"Subnet Mask"}
                  />}
        {enableAdvanced && enableStaticEth  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="gwEth"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"Gateway"}
                  />}
        {enableAdvanced && enableStaticEth  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="dns1Eth"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"DNS Server 1"}
                  />}
        {enableAdvanced && enableStaticEth  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="dns2Eth"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"DNS Server 2"}
                  />}
        {enableAdvanced && newSettings1 && <Dropdown
          labelText='Select 802.11 Wireless Mode'
          defaultValueProp={wifi802_11Mode}
          itemsArray= {[
            {label: '802.11b/g/n', value: '3'},
            {label: '802.11b/g', value: '2' },
            {label: '802.11b', value: '1' },
            ]}
          onChangeItemProp={setSelected802_11Mode}
        />}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Reduce Wireless Transmit power'
          switchState={reduceTxPower}
          setSwitchState={setReduceTxPower} 
          isDisabled = {false}
        />}
        {enableAdvanced && newSettings5 && <Switch 
          labelText='Invert Relay Direction'
          switchState={invertRelay}
          setSwitchState={setInvertRelay} 
          isDisabled = {false}
        />}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Enable Local Web Portal'
          switchState={enableWebPortal}
          setSwitchState={setEnableWebPortal} 
          isDisabled = {false}
        />}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Use Custom Keep Connect SSID'
          switchState={enableCustomSSID}
          setSwitchState={setEnableCustomSSID} 
          isDisabled = {false}
        />}
        {enableAdvanced && newSettings1 && enableCustomSSID  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="default"
                    name="ssid"
                    placeholder=""
                    labelText = {"Custom WiFi SSID"}
                  />}
        {enableAdvanced && newSettings5 && <Switch 
                    labelText='Enable Guest Mode'
                    switchState={GuestMode}
                    setSwitchState={setGuestMode} 
                    isDisabled = {false}
                  />}
        {enableAdvanced && newSettings5 && <Switch 
                    labelText='Enable MQTT'
                    switchState={enableMQTT}
                    setSwitchState={setEnableMQTT} 
                    isDisabled = {false}
                  />}
                    {enableAdvanced && enableMQTT && <>
                    <LabelFormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="default"
                      name="mqttHost"
                      placeholder=""
                      labelText="MQTT Host URL"
                      maxLength={98}
                      textAlignVertical="top"
                    />
                    <LabelFormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="default"
                      name="mqttClient"
                      placeholder=""
                      labelText="MQTT Client Name"
                      maxLength={98}
                      textAlignVertical="top"
                    />
                    <LabelFormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="default"
                      name="mqttUser"
                      placeholder=""
                      labelText="MQTT Username"
                      maxLength={98}
                      textAlignVertical="top"
                    />
                    <LabelFormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="default"
                      name="mqttPassword"
                      placeholder=""
                      labelText="MQTT Password"
                      maxLength={98}
                      textAlignVertical="top"
                    />
                    <LabelFormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="numeric"
                      name="mqttPort"
                      placeholder="1883"
                      labelText={"MQTT Port"}
                    />
                    </>}
        {enableAdvanced && <View style={{paddingTop: 20, borderTopWidth :3, borderTopColor: colors.primary}}></View>}
        {enableAdvanced && <Text style={{fontWeight: "bold", alignSelf: "center"}}>VPN Endpoint Settings:</Text>}
        {enableAdvanced && <Dropdown
          key={vpnMode}
          labelText='VPN Mode'
          defaultValueProp={vpnMode}
          itemsArray= {[
            {label: 'Disabled', value: '0'},
            {label: 'Auto', value: '1' },
            {label: 'Direct', value: '2' },
          ]}
          disabled = {!vpnEnabled || !enterpriseOrPro}
          onChangeItemProp={setVPNmode}
        />}
        {enableAdvanced && !enterpriseOrPro && <Text style={{fontSize: 12, color: "gray"}}>VPN Functionality requires Pro or Enterprise Subscription</Text>}
        {enableAdvanced && enterpriseOrPro && !vpnEnabled && <Text style={{fontSize: 12, color: "gray"}}>Firmware Upgrade Required to Enable</Text>}
        {enableAdvanced && vpnMode == 2 && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="vpnP"
                    placeholder=""
                    labelText = {"Device Listening Port"}
                  />}
        <Text></Text>
      <ErrorMessage
          error={requestMessage}
          visible={requestFailed}
        />
        <ErrorMessage
          error={serverMessage}
          visible={errorFromServer}
        />
      <SubmitButton title="Send Settings to Device" />
      <Button title="Import/Export Settings" onPress={() => setVisibleTrigger3(true)} />
        <Modal isVisible={visibleTrigger3} transparent={true} animationType = {"slide"} onBackdropPress={()=>setVisibleTrigger3(false)}>
          <View style={{height: 300,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
              <Text style={{alignSelf: "center"}}>How would you like to proceed?{'\n'}</Text>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {Platform.OS === 'web' ? (
                  <input
                    type="checkbox"
                    value={includeSettings}
                    onChange={e => setIncludeSettings(e.target.checked)}
                  />
                ) : (
                  <CheckBox value={includeSettings} onValueChange={setIncludeSettings} />
                )}
                <Text>Include Settings</Text>
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {Platform.OS === 'web' ? (
                  <input
                    type="checkbox"
                    value={includePipelines}
                    onChange={e => setIncludePipelines(e.target.checked)}
                  />
                ) : (
                  <CheckBox value={includePipelines} onValueChange={setIncludePipelines} />
                )}
                <Text>Include Pipelines</Text>
              </View>
              <Button title="Import Settings" onPress={()=>{loadSettingsFromFile(includeSettings, includePipelines);}} />
              <SubmitButton title="Export Settings" customAction={() => setSaveForExport(true)} />
            </View>
          </View>
        </Modal>
      </Form>
      <Modal isVisible={visibleTrigger} transparent={true} animationType = {"slide"}>
            <View style={{height: 250,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style = {{alignSelf: "center"}}>Are you sure you want to send these settings to:{'\n'}{route.params.item.Name}</Text>
            <Button title="Send It!" onPress={()=>handleSubmit()} />
            <Button title="Cancel" onPress={()=>cancelSend()} />
            </View>
            </View>
        </Modal>
        <Modal isVisible={visibleTrigger2} transparent={true} animationType = {"slide"} onBackdropPress={()=>navigation.goBack()}>
            <View style={{height: 200,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>How would you like to proceed?{'\n'}</Text>
            <Button title="View/Modify Settings" onPress={()=>initializeSettings(false)} />
            <Button title="Setup from Defaults" onPress={()=>initializeSettings(true)} />
            </View>
            </View>
        </Modal>
      </View>
      </React.Fragment>}
    </Screen>
);
    }

    const styles = StyleSheet.create({
      deviceBox: {
        borderWidth: 1,
        borderRadius: 20,
        borderColor: '#4eb9d6',
        padding: 10,
        marginBottom: 10,
    },
  });

export default ChangeSettingsScreen;