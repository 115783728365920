import apiClient from './client';


const getKeepConnects = (userId = null) => apiClient.get('/mobileapi/v1/get-keep-connects', {timestamp: Date.now(), userId}); //timestamp added to ensure get request is unique and would therefore bypass any caching enforced by servers down the road.
const getKeepConnectsFullData = () => apiClient.get('/mobileapi/v1/get-keep-connects-fulldata', {timestamp: Date.now(), licenseCheck2: true});
// const getCloudKeepConnects = () => apiClient.get('/mobileapi/v1/getCloudKeepConnects', {timestamp: Date.now()});
const getAppSettings = () => apiClient.get('/mobileapi/v1/getAppSettings', {timestamp: Date.now()});
const setAppSettings = (appSettings) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/setAppSettings', {appSettings: appSettings});
};
const setDeviceOrder = (data) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/setKeepConnectOrder', {deviceOrder: data});
};
const getCustomMsgs = (keepConnectCode) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/getCustomMsgs', {userMac: keepConnectCode});
};
const setCustomMsgs = (postData) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/setCustomMsgs', postData);
};
const getResetData = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetResetData', {userMac: keepConnectCode, range: days});
};
const getResetDataComprehensive = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetResetDataComprehensive', {userMac: keepConnectCode, range: days});
};
const getDHCPData = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetDHCPData2', {userMac: keepConnectCode, range: days});
};
const getResetDataLogs = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetResetDataLogs', {userMac: keepConnectCode, range: days});
};
const getResetDataLogsComprehensive = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetResetDataLogsComprehensive', {userMac: keepConnectCode, range: days});
};
const getDHCPDataLogs = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetDHCPDataLogs', {userMac: keepConnectCode, range: days});
};
const registerKeepConnect = (webID, code, name, parentFolderId = 'root', userId = null) => {
    return apiClient.post('/mobileapi/v1/add-keep-connect', {
        keepConnectWebID: webID,
        keepConnectCode: code,
        keepConnectName: name,
        parentFolderId,
        userId
    });
};

const removeKeepConnect = (code, userId = null) => {
    return apiClient.post('/mobileapi/v1/remove-keep-connect', {keepConnects: code, userId});
};

const renameKeepConnect = (code,newName, userId = null) => {
    return apiClient.post('/mobileapi/v1/rename-keep-connect', {keepConnects: code, newName, userId});
};
// const subscribeCloudKeepConnect = (code, delay) => {
//     //console.log("userMac: " + keepConnectCode);
//     return apiClient.post('/mobileapi/v1/addcloudkeepconnects', {offlineDelay: delay, keepConnects:code});
// };
// const removeCloudKeepConnect = (code) => {
//     //console.log("userMac: " + keepConnectCode);
//     return apiClient.post('/mobileapi/v1/removecloudkeepconnects', {keepConnects:code});
// };
const getKeepConnectSettings = (code) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetSettings', {userMac:code});
};
const getPipelines = (code) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetPipelines', {userMac:code});
};
const clearLogs = (code) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiClearLogs', {userMac:code});
};
const triggersKeepConnectApi = (postData) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiTriggers2', postData);
};
const getScheduledEvent = (postData) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.get('/mobileapi/v1/get-scheduled-event', {...postData, timestamp: Date.now()});
};
const setScheduledEvent = (postData) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/set-scheduled-event', postData);
};

const addUserAccount = (email, type, role) => {
    return apiClient.post('/mobileapi/v1/attach_existing_user', { email, type, role });
};

const createAndAddUserAccount = (email,username, password, type, role) => {
    return apiClient.post('/mobileapi/v1/create_and_attach_user', { email, username, password, type, role });
};

const removeUserAccount = (userId) => {
    return apiClient.post('/mobileapi/v1/remove-user-account', { user_id: userId });
};

const getEnterpriseAccounts = () => {
    return apiClient.get('/mobileapi/v1/get-enterprise-accounts', { timestamp: Date.now() });
};

const addFolder = (folderName, parentFolderId = 'root') => {
    return apiClient.post('/mobileapi/v1/add-folder', { folderName, parentFolderId });
};

const removeFolder = (folderId) => {
    return apiClient.post('/mobileapi/v1/remove-folder', { folderId });
};

const updateItemOrder = (parentFolderId, items) => {
    return apiClient.post('/mobileapi/v1/update-item-order', { parentFolderId, items });
};

const updateServerItemsFull = (keepConnectData) => {
    return apiClient.post('/mobileapi/v1/update-devices-full', { keepConnectData });
};

const moveDeviceToFolder = (deviceId, targetFolderId) => {
    return apiClient.post('/mobileapi/v1/move-device-to-folder', { deviceId, targetFolderId });
};


export default {
    moveDeviceToFolder,
    addFolder,
    removeFolder,
    updateItemOrder,
    updateServerItemsFull,
    getKeepConnects,
    getKeepConnectsFullData,
    getResetData,
    getResetDataComprehensive,
    getDHCPData,
    getResetDataLogs,
    getResetDataLogsComprehensive,
    getDHCPDataLogs,
    registerKeepConnect,
    removeKeepConnect,
    renameKeepConnect,
    getKeepConnectSettings,
    getPipelines,
    // getCloudKeepConnects,
    // subscribeCloudKeepConnect,
    // removeCloudKeepConnect,
    triggersKeepConnectApi,
    getAppSettings,
    setAppSettings,
    getCustomMsgs,
    setCustomMsgs,
    clearLogs,
    setDeviceOrder,
    addUserAccount,
    createAndAddUserAccount,
    removeUserAccount,
    getEnterpriseAccounts,
    getScheduledEvent,
    setScheduledEvent,
}