import React from 'react';
import { View, Text, TextInput, Switch, StyleSheet } from 'react-native';
import { Buffer } from 'buffer';
import colors from "../../config/colors";
import { FrameType } from './frameType';
//import styles from './styles'; // Note: You'll need to ensure that styles are imported or defined in this file

const KCRoundtripFrameComponent = ({ frameCore, index, updateFrameCore }) => {
    const toggleResponseCheck = (newValue) => {
      updateFrameCore({
        ...frameCore,
        checkResponse: newValue,
        responseType: newValue ? frameCore.responseType : 0,
      });
    };
  
    const toggleContentCheck = (newValue) => {
      updateFrameCore({
        ...frameCore,
        checkContent: newValue,
        content: newValue ? frameCore.content : '',
      });
    };
  
    const toggleSkipToAction = (newValue) => {
      updateFrameCore({
        ...frameCore,
        skipToActionOnFailure: newValue,
      });
    };
  
    return (
      <View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: 10,
          }}>
          <Text>URL Check Frame</Text>
          <Text>Frame {index + 1}</Text>
        </View>
  
        <Text>URL To Check:</Text>
        <TextInput
          style={styles.inputWide}
          placeholder="URL To Check"
          value={frameCore.targetURL}
          onChangeText={(text) => updateFrameCore({ ...frameCore, targetURL: text })}
        />
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Check for specific response:</Text>
          <Switch
            value={frameCore.checkResponse}
            onValueChange={toggleResponseCheck}
          />
        </View>
        {frameCore.checkResponse && (
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text>Response Type:</Text>
            <TextInput
              style={styles.input}
              placeholder="Response Type"
              value={frameCore.responseType.toString()}
              onChangeText={(text) =>
                updateFrameCore({ ...frameCore, responseType: parseInt(text) })
              }
            />
          </View>
        )}
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Check for response content:</Text>
          <Switch value={frameCore.checkContent} onValueChange={toggleContentCheck} />
        </View>
        {frameCore.checkContent && (
          <>
            <Text>Content:</Text>
            <TextInput
              style={styles.inputWide}
              placeholder="Content"
              value={frameCore.content}
              onChangeText={(text) => updateFrameCore({ ...frameCore, content: text })}
            />
          </>
        )}
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Loop Repeat:</Text>
          <TextInput
            style={styles.input}
            placeholder="Loop Repeat"
            value={frameCore.loopRepeat.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, loopRepeat: parseInt(text) })
            }
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Loop to Starts:</Text>
          <TextInput
            style={styles.input}
            placeholder="Loop to Starts"
            value={frameCore.loopToStarts.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, loopToStarts: parseInt(text) })
            }
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Skip to Action On Failure:</Text>
          <Switch
            value={frameCore.skipToActionOnFailure}
            onValueChange={toggleSkipToAction}
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Timeout:</Text>
          <TextInput
            style={styles.input}
            placeholder="Timeout"
            value={frameCore.timeout.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, timeout: parseInt(text) })
            }
          />
        </View>
  
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Delay Before{'\n'}Proceed: (Seconds)</Text>
          <TextInput
            style={styles.input}
            placeholder="Delay Before Proceed"
            value={frameCore.delayBeforeProceed.toString()}
            onChangeText={(text) =>
              updateFrameCore({ ...frameCore, delayBeforeProceed: parseInt(text) })
            }
          />
        </View>
      </View>
    );
  };
  
  KCRoundtripFrameComponent.newFrameCore = () => {
    frameCore1 = {
        frameType: FrameType.URL_CHECK_FRAME,
        version: 1,
        targetURL: 'test1.johnson-creative.com',
        checkResponse: true,
        responseType: 200,
        checkContent: true,
        content: 'kcSuccess',
        loopRepeat: 0,
        loopToStarts: 0,
        skipToActionOnFailure: false,
        timeout: 20,
        delayBeforeProceed: 60,
    };

    frameCore2 = {
        frameType: FrameType.URL_CHECK_FRAME,
        version: 1,
        targetURL: 'test2.johnson-creative.com',
        checkResponse: true,
        responseType: 200,
        checkContent: true,
        content: 'kcSuccess',
        loopRepeat: 0,
        loopToStarts: 0,
        skipToActionOnFailure: false,
        timeout: 20,
        delayBeforeProceed: 0,
    };

    return [frameCore1, frameCore2];
    };
  
  

  const styles = StyleSheet.create({
    TextStyle: {
            color: colors.primary,
            alignSelf: "center",
        },
    picker: {
            height: 50,
            width: '75%',
            alignSelf: 'center',  // Center the picker
            },
            frameCore: {
            borderWidth: 1,
            borderRadius: 10,
            borderColor: colors.primary,
            margin: 10,
            padding: 10,
            },
            input: {
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 4,
            padding: 5,
            marginBottom: 10,
            width: '50%', // use the full width
            },
            inputWide: {
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 4,
            padding: 5,
            marginBottom: 10,
            width: '100%', // use the full width
            },
    });

  export default KCRoundtripFrameComponent;