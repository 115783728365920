import React, { useState, useEffect } from "react";
import { StyleSheet, Image, View, Text, ActivityIndicator } from "react-native";
//import DropDownPicker from 'react-native-dropdown-picker';
import Dropdown from '../components/Dropdown';
import * as Yup from "yup";

import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
} from "../components/forms";

import Screen from '../components/Screen';
import alert from '../components/alert';
import Button from '../components/Button';
import routes from "../navigation/routes";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import colors from "../config/colors";

function CloudNotificationsScreen({ navigation }){
    const subscribeCloudKeepConnectApi = useApi(keepConnectApi.subscribeCloudKeepConnect);  
    const getCloudKeepConnectApi = useApi(keepConnectApi.getCloudKeepConnects);
    const getKeepConnectApi = useApi(keepConnectApi.getKeepConnects);
    const removeCloudKeepConnectApi = useApi(keepConnectApi.removeCloudKeepConnect);  
    const [isLoading, setIsLoading] = useState(true);
    const [requestFailed, setRequestFailed] = useState(false);
    const [requestMessage, setRequestMessage] = useState("Error Communicating with Server");
    const [errorFromServer, setErrorFromServer] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [keepConnectsArray, setKeepConnectsArray] = useState([{label: "No Keep Connects Registered", value: "null"}]);
    const [cloudKeepConnectsArray, setCloudKeepConnectsArray] = useState([{label: "No Cloud Keep Connects Subscribed", value: "null"}]);
    const [selectedValue, setSelectedValue]= useState();


    useEffect(() => {
        loadKeepConnects();
    }, []);

    const loadKeepConnects = async () => {
        const response = await getCloudKeepConnectApi.request();
        if (!response.ok) return setRequestFailed(true);
        if(!response.data){
          setIsLoading(false);
          return;
        }

        if(!response.data.registered) navigation.navigate(routes.ADDREMOVEKEEPCONNECTS);
        let jsonObj = [];
        Object.keys(response.data.registered).forEach(function(key) {
            jsonObj.push({
                label: key + " " + response.data.registered[key],
                value: response.data.registered[key]
            });
          });
        setKeepConnectsArray(jsonObj);
        setSelectedValue(jsonObj[0].value);

        
        if(!response.data.cloud) return setIsLoading(false);
        let jsonObj2 = [];
        //let displayKeepConnects=<Text>Registered Keep Connects:</Text>;
        Object.keys(response.data.cloud).forEach(function(key) {
            //displayKeepConnects+=<Text>{keepConnects[key]}</Text>;
            jsonObj2.push({
                label: key + " " + response.data.cloud[key],
                value: response.data.cloud[key]
            });
          });
        setCloudKeepConnectsArray(jsonObj2);
        setIsLoading(false);
        }

    const validationSchema = Yup.object().shape({
        offlineDelay: Yup.number().required().min(20).label("Offline Delay"),
        });
  

    const handleSubmit = async ({ offlineDelay}) => {
        if (selectedValue == null){
            return;
        }
        setIsLoading(true);
        const response = await subscribeCloudKeepConnectApi.request(selectedValue, offlineDelay);
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        alert(
          "Success!",
          "Your Keep Connect was successfully registered for Cloud Notifications",
          [
            { text: "OK", onPress: () => {} }
          ]
        );
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        loadKeepConnects();
        };

    const onChangeDropdown = (itemValue) => {
        setSelectedValue(itemValue);
    }

    const removeKeepConnect = async ()=>{
        if (selectedValue == null){
            return;
        }
        setIsLoading(true);
        const response = await removeCloudKeepConnectApi.request(selectedValue);
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        alert(
          "Success!",
          "Your Keep Connect was successfully removed from cloud notifications.",
          [
            { text: "OK", onPress: () => {} }
          ]
        );
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        loadKeepConnects();
        };

    
    return(
    <Screen>
        {isLoading ? <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View> :
        <React.Fragment>
        <View style={{padding: 20, borderBottomWidth :3, borderBottomColor: colors.primary}}>
        <ErrorMessage
          error={requestMessage}
          visible={requestFailed}
        />
        <ErrorMessage
          error={serverMessage}
          visible={errorFromServer}
        />
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Cloud Keep Connects Subscribed:</Text>
        {cloudKeepConnectsArray.map((d)=><Text key={d.label} style={{alignSelf: "center"}}>{d.label}</Text>)}
        </View>
        <View style={{padding: 20, borderBottomWidth :3, borderBottomColor: colors.primary}}>
        <Text></Text>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Select Device to be Subscribed/Removed</Text>
        <Dropdown
            itemsArray={keepConnectsArray}
            defaultValueProp={keepConnectsArray[0].value}
            containerStyle={{height: 40}}
            style={{backgroundColor: '#fafafa'}}
            itemStyle={{
                justifyContent: 'flex-start'
            }}
            dropDownStyle={{backgroundColor: '#fafafa'}}
            onChangeItemProp={ item => onChangeDropdown(item)}
        />

        <Form
        initialValues={{ offlineDelay: ""}}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        >
        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          name="offlineDelay"
          keyboardType="numeric"
          placeholder="Offline Delay (In Minutes)"
          textContentType="none"
          helpText="Cloud Notifications works by monitoring a heartbeat signal from your device. If it does not see a heartbeat for the amount of Offline Delay time, it will then send you a notification letting you know your device is offline."
        />
        <SubmitButton title="Subscribe Keep Connect" />
      </Form>
      <Button
            title="Remove Selected Keep Connect"
            onPress={removeKeepConnect}
        />
        </View>
      </React.Fragment>}
    </Screen>
);
    }

export default CloudNotificationsScreen;