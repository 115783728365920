import React, { useEffect, useState } from 'react';
import { Text, View, Dimensions, ActivityIndicator, Platform, StyleSheet } from 'react-native';
//import DropDownPicker from 'react-native-dropdown-picker';
import Dropdown from '../components/Dropdown';
import { LineChart } from 'react-native-chart-kit';
//import Modal from 'react-native-modal';
import Modal from '../components/PlatformModal';
import moment from 'moment-timezone';

import Screen from '../components/Screen';
import alert from '../components/alert';
import Button from '../components/Button';
import routes from "../navigation/routes";
//import { color } from 'react-native-reanimated'; //NOT USED
import colors from '../config/colors';
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import useApi from "../hooks/useApi";
import KeepConnectChart from "../components/KeepConnectChart";
import { FlatList } from '../components/PlatformWrapperFlatlistFromGestureHandler';

function DashboardScreen({ route, navigation }){ 
    const [dates, setDates] = useState([]);
    const [dates2, setDates2] = useState([]);
    const [totalResets, setTotalResets] = useState([]);
    const [autoResetResets, setAutoResets] = useState([]);
    const [tcp_tls_failResets, setTcp_tls_fail] = useState([]);
    const [httpFailResets, setHttpFail] = useState([]);
    const [DNSfailResets, setDNSfail] = useState([]);
    const [unknownFailResets, setUnknownFail] = useState([]);
    const [WiFiFailResets, setWiFiFail]= useState([]);
    const [dhcp, setDHCP]= useState([]);
    const [isLoading, setIsloading]=useState(true);
    const [refreshing, setRefreshing ] = useState(false);
    const [updateNeeded, setUpdateNeeded] = useState(false);
    const [emptyResetData, setEmptyResetData] = useState(true);
    const [emptyDHCPData, setEmptyDHCPData] = useState(true);
    const [selectedValue, setSelectedValue] = useState(30);
    const [visibleTrigger, setVisibleTrigger] = useState(true);
    const [visibleTrigger2, setVisibleTrigger2] = useState(false);
    const getKeepConnectResetsApi = useApi(keepConnectApi.getResetData);
    const getKeepConnectDHCPApi = useApi(keepConnectApi.getDHCPData);
    const getKeepConnectResetsLogsApi = useApi(keepConnectApi.getResetDataLogs);
    const getKeepConnectDHCPLogsApi = useApi(keepConnectApi.getDHCPDataLogs);
    const [resetLogs, setResetLogs] = useState([]);
    const [DHCPLogs, setDHCPLogs] = useState([]);
    const [viewCharts, setViewCharts] = useState(true);
    const [appTimezone, setAppTimeZone] = useState("America/New_York");
    const clearDeviceLogsApi = useApi(keepConnectApi.clearLogs); 
    const [errorFromServer, setErrorFromServer] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [requestFailed, setRequestFailed] = useState(false);


    /*
    useEffect(() => {
        loadResetData(30);
    }, []);
    */

    let name = [];
    let total = [];
    let autoReset = [];
    let tcp_tls_fail = [];
    let httpFail = [];
    let DNSfail = [];
    let unknownFail = [];
    let WiFiFail = [];
    let nameDHCP = [];
    let dhcpChanges = [];

    const loadResetData = async (days) => {
        const keepConnectCode = await keepConnectStore.get("keepConnect");
        const response = await getKeepConnectResetsApi.request(keepConnectCode, days);
        const response2 = await getKeepConnectDHCPApi.request(keepConnectCode, days);
        

        for (var i in response.data) {
            name.push(response.data[i].DateOnly);
            total.push(Number(response.data[i].total));
            autoReset.push(Number(response.data[i].Auto));
            tcp_tls_fail.push(Number(response.data[i].TCP_TLS));
            httpFail.push(Number(response.data[i].Http));
            DNSfail.push(Number(response.data[i].DNS));
            unknownFail.push(Number(response.data[i].Unknown));
            WiFiFail.push(Number(response.data[i].WiFi));
        };

        for (var i in response2.data) {
            nameDHCP.push(response2.data[i].DateOnly);
            dhcpChanges.push(Number(response2.data[i].counts));
        }
        setDates(name);
        setTotalResets(total);
        setAutoResets(autoReset);
        setTcp_tls_fail(tcp_tls_fail);
        setHttpFail(httpFail);
        setDNSfail(DNSfail);
        setUnknownFail(unknownFail);
        setWiFiFail(WiFiFail);
        setDates2(nameDHCP);
        setDHCP(dhcpChanges);

        setIsloading(false);
        setRefreshing(false);

        if( !(name.length > 0) ) setEmptyResetData(true);
        else setEmptyResetData(false);
        if( !(nameDHCP.length > 0) ) setEmptyDHCPData(true);
        else setEmptyDHCPData(false);
    }
    
    const loadResetLogs = async (days) => {
        const keepConnectCode = await keepConnectStore.get("keepConnect");
        const storedTimezone = await keepConnectStore.get("kc_appTimezone");
        const correctedStoredTimezone = (storedTimezone !== null ? storedTimezone.replace(/\"/g, "") : "America/New_York");
        setAppTimeZone(correctedStoredTimezone);
        moment.tz.setDefault("America/New_York");
        const response = await getKeepConnectResetsLogsApi.request(keepConnectCode, days);
        const response2 = await getKeepConnectDHCPLogsApi.request(keepConnectCode, days);

        setResetLogs(response.data.reverse());
        setDHCPLogs(response2.data.reverse());

        //console.log(response);
        //console.log(response2);

        setIsloading(false);
        setRefreshing(false);
    }

    const timeFrames = [
        {label: "30 Days", value: 30},
        {label: "60 Days", value: 60}, 
        {label: "90 Days", value: 90}, 
        {label: "180 Days", value: 180}, 
        {label: "All Time", value: 10000}, 
    ]

    const initializeSettings = async (charts) => {
        setVisibleTrigger(!visibleTrigger);
        setIsloading(true);
        setViewCharts(charts);
        if(charts){
            loadResetData(30);
        } else{
            loadResetLogs(30);
        }
      };

    
    const onChangeDropdown = (itemValue) => {
        setIsloading(true);
        setSelectedValue(itemValue);
        if(viewCharts) loadResetData(itemValue);
        else loadResetLogs(itemValue);
        }


    const renderResetItems = ({ item }) => {
        moment.tz.setDefault("America/New_York");
        var timestamp = moment.utc(item.time.replace(/\"/g, ""));

        return(
        <View style={{borderBottomColor: "#4eb9d6", borderBottomWidth: 1}}><Text>{timestamp.tz(appTimezone).format('YYYY-MM-DD HH:mm:ss')} Reason: {item.reason}</Text></View>);
        };

    const renderDHCPItems = ({ item }) => {
        moment.tz.setDefault("America/New_York");
        var timestamp = moment.utc(item.switchTime.replace(/\"/g, ""));
    
        return(
        <View style={{borderBottomColor: "#4eb9d6", borderBottomWidth: 1}}><Text style={{alignSelf: "center"}}>{timestamp.tz(appTimezone).format('YYYY-MM-DD HH:mm:ss')} ({moment().tz(appTimezone).format('z')})</Text></View>);
        };


    if (updateNeeded){
        if(Platform.OS === 'web') setIsloading(true);
        setRefreshing(true);
        setUpdateNeeded(false);
        if(viewCharts) loadResetData(selectedValue);
        else loadResetLogs(selectedValue);
    }

    const clearDeviceLogs = async () => {
        setVisibleTrigger2(false);
        const keepConnectCode = route.params.item.MAC;
        const response = await clearDeviceLogsApi.request(keepConnectCode.replace(/\"/g, ""));
        if (!response.ok){
            setIsloading(false);
            return setRequestFailed(true);
        }
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsloading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsloading(false);
          return;
        }
        alert(
          "Success!",
          "Device Logs Successfully Cleared!",
          [
            { text: "OK", onPress: () => {} }
          ]
        );
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        //loadKeepConnects();
        route.params.changedDevices(true);
        setUpdateNeeded(true);
        }

        var isPowerPal = route.params.item.firmware.includes("PowerPal");
        
        return(
        <Screen isLoading={refreshing} setUpdateNeeded={setUpdateNeeded}>
        {isLoading ? <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View> :
        <View>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Showing Event Data for:</Text>
        <Text style={{alignSelf: "center"}}>{route.params.item.Name}</Text><Text></Text>
        <Text style={styles.TextStyle} onPress={ ()=> setVisibleTrigger2(true) } >  Clear Device Logs</Text>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>For the past:</Text>
        <Dropdown
            itemsArray={timeFrames}
            defaultValueProp={selectedValue}
            onChangeItemProp={ item => onChangeDropdown(item)}
        /><Text></Text><Text></Text>
        {Platform.OS === 'ios' ? <></> : <Text style={styles.TextStyle} onPress={ ()=> setUpdateNeeded(true) } >Refresh</Text>}
        {viewCharts ?
                <React.Fragment>
                {emptyResetData ? <><Text></Text><Text style={{fontWeight: "bold", alignSelf: "center"}}>No Reset Data Exists in the last {selectedValue} days</Text><Text></Text></> : <React.Fragment></React.Fragment>}
                {!emptyResetData ? <KeepConnectChart label={dates} data={totalResets} text={"Total Events"} /> : <></>}
                {!isPowerPal && !emptyResetData ? <KeepConnectChart label={dates} data={autoResetResets} text={"Auto Resets"} /> : <></>}
                {!isPowerPal && !emptyResetData ? <KeepConnectChart label={dates} data={tcp_tls_failResets} text={"TCP / TLS Failure Resets"} /> : <></>}
                {!isPowerPal && !emptyResetData ? <KeepConnectChart label={dates} data={httpFailResets} text={ "HTTP Failure Resets"} /> : <></>}
                {!isPowerPal && !emptyResetData ? <KeepConnectChart label={dates} data={DNSfailResets} text={"DNS Failure Resets"} /> : <></>}
                {!isPowerPal && !emptyResetData ? <KeepConnectChart label={dates} data={WiFiFailResets} text={"WiFi Failure Resets"} /> : <></>}
                {!isPowerPal && !emptyResetData ? <KeepConnectChart label={dates} data={unknownFailResets} text={"Other Events / Resets"} />  : <></>}
                {!isPowerPal && (emptyDHCPData ? <><Text></Text><Text style={{fontWeight: "bold", alignSelf: "center"}}>No IP Address Change Data Exists in the last {selectedValue} days</Text><Text></Text></> : 
                <KeepConnectChart label={dates2} data={dhcp} text={"IP Address Changes"} />)}
                </React.Fragment> :
                <React.Fragment>
                <Text style={{fontWeight: "bold", alignSelf: "center"}}>Event Data Log:</Text>
                <Text style={{alignSelf: "center"}}>Timezone: ({moment().tz(appTimezone).format('z')}) {'\n'}</Text>
                {resetLogs.length !== 0 ?
                <FlatList data={resetLogs} renderItem={renderResetItems} keyExtractor={item => item.time} />
                : <><Text></Text><Text style={{alignSelf: "center"}}>No data for this range</Text></>
                }
                <Text></Text>
                <Text style={{fontWeight: "bold", alignSelf: "center"}}>IP Change Data Log:</Text>
                <Text style={{alignSelf: "center"}}>Timezone: ({moment().tz(appTimezone).format('z')}) {'\n'}</Text>
                {DHCPLogs.length !== 0 ?
                <FlatList data={DHCPLogs} renderItem={renderDHCPItems} keyExtractor={item => item.time} />
                : <><Text></Text><Text style={{alignSelf: "center"}}>No data for this range</Text></>
                }
                </React.Fragment>
        }
        </View>
        }
        <Modal isVisible={visibleTrigger} transparent={true} animationType = {"slide"} onBackdropPress={()=>navigation.goBack()}>
            <View style={{height: 200,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>Please select:{'\n'}</Text>
            <Button title="Charts" onPress={()=>initializeSettings(true)} />
            <Button title="Logs" onPress={()=>initializeSettings(false)} />
            </View>
            </View>
        </Modal>
        <Modal isVisible={visibleTrigger2} transparent={true} animationType = {"slide"} onBackdropPress={()=>setVisibleTrigger2(false)}>
            <View style={{height: 200,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>Are you sure that you want to clear all logs for this device?{'\n'}</Text>
            <Button title="Proceed" onPress={()=>clearDeviceLogs()} />
            <Button title="Cancel" onPress={()=>setVisibleTrigger2(false)} />
            </View>
            </View>
        </Modal>
    </Screen>
);
    }

const styles = StyleSheet.create({
    TextStyle: {
            color: colors.primary,
            alignSelf: "center",
        },
    });

export default DashboardScreen;