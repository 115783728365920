import * as SecureStore from "expo-secure-store";
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from "jwt-decode";
import { Platform } from "react-native";

const key = "authToken";
const permissionsKey = "userPermissions";
const isDesktop = Platform.OS === 'windows' || Platform.OS === 'macos';

/**
 * Stores the user permissions object in AsyncStorage.
 * 
 * The permissions object should have the following format:
 * {
 *   license: string, // e.g., "basic", "pro", "enterprise"
 *   role: string     // e.g., "admin", "user", "manager"
 * }
 * 
 * @param {Object} permissions - The permissions object to store
 */
const storePermissions = async (permissions) => {
  try {
    const jsonValue = JSON.stringify(permissions);
    await AsyncStorage.setItem(permissionsKey, jsonValue);
  } catch (error) {
    console.log("Error storing the permissions", error);
  }
};

/**
 * Retrieves the user permissions object from AsyncStorage.
 * 
 * @returns {Object|null} The permissions object if found, null otherwise.
 * The returned object has the following format:
 * {
 *   license: string, // e.g., "basic", "pro", "enterprise"
 *   role: string     // e.g., "admin", "user", "manager"
 * }
 */
const getPermissions = async () => {
  try {
    const jsonValue = await AsyncStorage.getItem(permissionsKey);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (error) {
    console.log("Error getting the permissions", error);
    return null;
  }
};

const storeToken = async (authToken) => {
  if (!(Platform.OS === 'web' || isDesktop)){
  try {
    await SecureStore.setItemAsync(key, authToken);
  } catch (error) {
    console.log("Error storing the auth token", error);
  }
      } else{
        try {
          await AsyncStorage.setItem(key, authToken);
        } catch (error) {
          console.log("Error storing the auth token (even from Async for Web)", error);
        }
      }
};

const getToken = async () => {
  if (!(Platform.OS === 'web' || isDesktop)){
  try {
    return await SecureStore.getItemAsync(key);
  } catch (error) {
    console.log("Error getting the auth token", error);
  }
      } else{
        console.log("get from async");
        try {
          return await AsyncStorage.getItem(key);
        } catch (error) {
          console.log("Error getting the auth token (even from Async for Web)", error);
        }
      }
};

const getUser = async () => {
  const token = await getToken();
  return token ? jwtDecode(token) : null;
};

const removeToken = async () => {
  if (!(Platform.OS === 'web' || isDesktop)){
  try {
    console.log("removing token");
    await SecureStore.deleteItemAsync(key);
  } catch (error) {
    console.log("Error removing the auth token", error);
  }
  } else{
    try {
      console.log("removing token");
      await AsyncStorage.removeItem(key);
    } catch (error) {
      console.log("Error removing the auth token (even from Async for web)", error);
    }  
  }
};

export default { getToken, getUser, removeToken, storeToken, storePermissions, getPermissions };
