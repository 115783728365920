export const FrameType = {
    URL_CHECK_FRAME: 0,
    PING_FRAME: 1,
    //JITTER_FRAME: 2,
    //SPEEDTEST_FRAME: 3,
    WIFI_CHECK_FRAME: 4,
    KC_ROUNDTRIP_FRAME: 5,
    //DEVICE_REBOOT_FRAME: 6,
    //ONGOING_PING_COUNTER_FRAME: 7,
    //API_MESSAGE_ACTION_FRAME: 8,
    POWER_CYCLE_FRAME: 9,
};