import React, {useState} from "react";
//import Constants from "expo-constants";
import { StyleSheet, SafeAreaView, View, TouchableOpacity, Image, Text, Linking } from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {MaterialIcons} from '../components/PlatformSpecificIcons';
//import Modal from 'react-native-modal';
import Modal from '../components/PlatformModal';

import Button from '../components/Button';
import useAuth from "../auth/useAuth";
import routes from "../navigation/routes";
import colors from '../config/colors';

function Screen({ children, style, navigation, bwj_upStreamRefreshNeeded, isLoading, setUpdateNeeded, keepConnects, showSettingsIcon }) {
  const [visibleTrigger, setVisibleTrigger] = useState(false);
  const auth = useAuth();

  const toggleModal = () => {
    setVisibleTrigger(!visibleTrigger);
    };

  return (
    <SafeAreaView style={[styles.screen, style]}>
      <View  style={{flex:1}}>
      {navigation !== undefined && !isLoading && showSettingsIcon ? <TouchableOpacity style={styles.settingsButton} onPress={()=>toggleModal()}>
      <MaterialIcons name="settings" size={32} color="#4eb9d6" />
      </TouchableOpacity> : <></>}
      <View style={styles.logoContainer}>
        <Image 
            source= {require('../assets/KeepConnectlogoblankback.png')}
            style = {styles.logo}
        />
        </View> 
      <View style={[styles.view, style]}>{children}</View>
      <Modal isVisible={visibleTrigger} transparent={false} animationIn="slideInLeft" animationOut="slideOutLeft" backdropColor="white">
            <View style={{flex: 1, alignItems: "center"}}>
            <View style={{flex:8, justifyContent: "center", alignItems: "center",width:300}}>
            {/* <Button title="Add/Remove Keep Connects" onPress={()=>{setVisibleTrigger(false); navigation.navigate(routes.ADDREMOVEKEEPCONNECTS, {changedDevices: setUpdateNeeded});}} /> */}
            {/* {auth.userPermissions.role === 'admin' && <Button title="Cloud Notifications" onPress={()=>{setVisibleTrigger(false); navigation.navigate(routes.CLOUDNOTIFICATIONS);}} />} */}
            {auth.userPermissions.license === 'enterprise' && auth.userPermissions.role === 'admin' && <Button title="Manage Users" onPress={()=>{setVisibleTrigger(false); navigation.navigate(routes.USERMANAGEMENT);}} />}
            <Button title="App Settings" onPress={()=>{setVisibleTrigger(false); navigation.navigate(routes.APPSETTINGS);}} />
            <Button title="Comprehensive Logs" onPress={()=>{setVisibleTrigger(false); navigation.navigate(routes.DASHBOARDCOMPREHENSIVE, {keepConnects: keepConnects});}} />
            <Button title="Help" onPress={ ()=> Linking.openURL('https://www.johnson-creative.com/docs') } />
            <Button title="Logout" onPress={()=>auth.logOut()} />
            </View>
            <View style={{flex:2,width:300,justifyContent: "flex-end", alignContent:"flex-end" }}>
            <Button title="Back" onPress={()=>setVisibleTrigger(false)} />
            <View style={{paddingVertical: 10, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle} onPress={ ()=> Linking.openURL('mailto: support@johnson-creative.com') } >Support</Text></View><View style={{flex: 1}}></View><View style={{flex: 1}}><Text style={styles.TextStyle} onPress={ ()=> Linking.openURL('https://www.johnson-creative.com/keep-connect-cloud-services-mobile-app-privacy-policy/') } >Privacy</Text></View></View>
            </View>
            </View>
        </Modal>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  view: {
    flex: 1,
    minWidth: 200,
    top: 100,
    padding: 20
  },
  logoContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    alignItems: "center",
},
  settingsButton: {
    position: "absolute",
    zIndex: 1,
    top: 10,
    right: 10,
    alignItems: "center",
    height: 30,
    width: 30,
},
logo: {
  width: 175,
  height: 175,
  resizeMode: 'contain',
},
TextStyle: {
     color: colors.primary,
     alignSelf: "center",
  }
});

export default Screen;
