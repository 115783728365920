import React from "react";
import { useFormikContext } from "formik";
import { View, Text } from 'react-native';

import TextInput from "../TextInput";
import ErrorMessage from "./ErrorMessage";

function AppLabelFormField({ name, width, labelText, ...otherProps }) {
  const {
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    values,
  } = useFormikContext();

  return (
    <>
      <Text style={{paddingTop: 20, paddingBottom: 0}}>{labelText}</Text>
      <TextInput
        onBlur={() => setFieldTouched(name)}
        onChangeText={(text) => setFieldValue(name, text)}
        value={values[name]}
        width={width}
        {...otherProps}
      />
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

export default AppLabelFormField;
