import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { useFormikContext } from "formik";
import { MultiSelect } from 'react-native-element-dropdown';

import PopHelp from '../PopHelp';  // Adjust the import path as needed
import ErrorMessage from "./ErrorMessage";  // Adjust the import path as needed

function FormikMultiselect({ 
  name, 
  labelText, 
  itemsArray, 
  formikOut, 
  onTop, 
  disabled = false, 
  width, 
  helpText, 
  ...otherProps 
}) {
  const {
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    values,
  } = useFormikContext();

  const handleChange = (selectedItems) => {
    setFieldValue(name, selectedItems);
    setFieldTouched(name);
    formikOut && formikOut(selectedItems);
  };

  return (
    <>
      <View style={{flexDirection:"row"}}>
        <View style={{flex:9}}>
          {labelText && <Text style={styles.label}>{labelText}</Text>}
          <MultiSelect
            style={[styles.dropdown, { width }]}
            data={itemsArray}
            labelField="label"
            valueField="value"
            value={values[name]}
            onChange={handleChange}
            disable={disabled}
            {...otherProps}
          />
        </View>
        {helpText ? (
          <View style={{flex:1, justifyContent: 'center'}}>
            <PopHelp>{helpText}</PopHelp>
          </View>
        ) : null}
      </View>
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

const styles = StyleSheet.create({
  label: {
    fontSize: 16,
    marginBottom: 5,
    alignSelf: 'center',
  },
  dropdown: {
    height: 40,
    backgroundColor: '#fafafa',
    borderRadius: 8,
    paddingHorizontal: 8,
  },
});

export default FormikMultiselect;