import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";

const storeSettings = async (key, vpnSettings) => {
  if (Platform.OS !== 'web'){
  try {
    const vpnSettingsString = JSON.stringify(vpnSettings); // Stringify the settings
    await SecureStore.setItemAsync(key.replace(/:/g, ''), vpnSettingsString);
  } catch (error) {
    console.log("Error storing the auth token", error);
  }
      }
};

const getSettings = async (key) => {
  if (Platform.OS !== 'web' ){
  try {
    const vpnSettingsString = await SecureStore.getItemAsync(key.replace(/:/g, ''));
    const vpnSettings = JSON.parse(vpnSettingsString); // Parse the settings
    return vpnSettings;
  } catch (error) {
    console.log("Error getting the auth token", error);
  }
      }
};

const removeSettings = async (key) => {
  if (Platform.OS !== 'web' ){
  try {
    console.log("removing token");
    await SecureStore.deleteItemAsync(key.replace(/:/g, ''));
  } catch (error) {
    console.log("Error removing the auth token", error);
  }
  }
};

export default { getSettings, removeSettings, storeSettings };