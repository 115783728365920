import React, { useEffect, useState } from 'react';
import { Text, View, Dimensions, Platform } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import colors from '../config/colors';


function KeepConnectChart({label, data, text}){
    return(
        <React.Fragment>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>{text}</Text>
        <LineChart
        data={{
        labels: label,
        datasets: [
            {
            data: data
            }
        ]
        }}
        width={Dimensions.get("window").width - 50} // from react-native
        height={500}
        yAxisInterval={1} // optional, defaults to 1
        verticalLabelRotation={Platform.OS !== 'web' ? 90 : 0}
        chartConfig={{
        backgroundColor: "#e26a00",
        backgroundGradientFrom: colors.primary,
        backgroundGradientTo: colors.primary,
        decimalPlaces: 1, // optional, defaults to 2dp
        color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        style: {
            borderRadius: 16
        },
        propsForDots: {
            r: "6",
            strokeWidth: "2",
            stroke: "#ffa726"
        }
        }}
        bezier
        style={{
        marginVertical: 8,
        borderRadius: 16
        }}
    />
    <Text></Text><Text></Text>
    </React.Fragment>
    );
}

export default KeepConnectChart;