import React from "react";
import { View } from "react-native";
import { useFormikContext } from "formik";

import Dropdown from "../Dropdown";  // Adjust the import path as needed
import PopHelp from '../PopHelp';  // Adjust the import path as needed
import ErrorMessage from "./ErrorMessage";  // Adjust the import path as needed

function FormikDropdown({ name, labelText, itemsArray, formikOut, onTop, disabled, width, helpText, ...otherProps }) {
  const {
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    values,
  } = useFormikContext();

  return (
    <>
      <View style={{flexDirection:"row"}}>
        <View style={{flex:9}}>
          <Dropdown
            labelText={labelText}
            defaultValueProp={values[name]}
            itemsArray={itemsArray}
            onChangeItemProp={(value) => {
              setFieldValue(name, value);
              setFieldTouched(name);
              formikOut && formikOut(value);
            }}
            onTop={onTop}
            disabled={disabled}
            {...otherProps}
          />
        </View>
        {helpText ? (
          <View style={{flex:1, justifyContent: 'center'}}>
            <PopHelp>{helpText}</PopHelp>
          </View>
        ) : null}
      </View>
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

export default FormikDropdown;