import React from "react";
import { View, Text, TextInput, StyleSheet } from "react-native";
import ErrorMessage from "./ErrorMessage";

function AppLabelFormFieldWithoutFormik({ labelText, value, onChangeText, ...otherProps }) {
  return (
    <>
      <Text style={styles.label}>{labelText}</Text>
      <TextInput
        style={styles.input}
        value={value}
        onChangeText={onChangeText}
        {...otherProps}
      />
      <ErrorMessage error={""} visible={false} />
    </>
  );
}

const styles = StyleSheet.create({
  label: {
    paddingTop: 20,
    paddingBottom: 0
  },
  input: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingVertical: 10,
  }
});

export default AppLabelFormFieldWithoutFormik;
