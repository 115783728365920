import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
//import { MaterialIcons } from '@expo/vector-icons';
import { MaterialIcons } from "./PlatformSpecificIcons";
import { Popable } from 'react-native-popable';

import Text from "./Text";

function PopHelp({ children, style, ...otherProps }) {
  return (
    <Popable 
    backgroundColor="#4eb9d6"
    content={children}
    caret={false}>
    <MaterialIcons name="help-outline" size={24} color="#4eb9d6" />
  </Popable>
  );
}

const styles = StyleSheet.create({
  text: {
    padding: 20,
  },
});

export default PopHelp;